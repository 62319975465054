import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, CardImg, Input } from "reactstrap"

import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import CurrencyFormat from "react-currency-format"
import pallys from "../../assets/images/pallys.png"
import auto from "../../assets/images/auto-partners.png"
import cash from "../../assets/images/cash.png"
import "./UserProfile.css"

import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import { FaArrowLeft, FaSyncAlt } from "react-icons/fa"

// defaults.global.legend.position = "bottom"

const UsersProfile = props => {
  const [profile, setProfile] = useState([])
  const [loading, setLoading] = useState(true)
  const [loanLimit, setLoanLimit] = useState({})
  const [income, setIncome] = useState({})
  const [merchantNumber, setMerchantNumber] = useState([])
  const [totalLoanAmount, setTotalLoanAmount] = useState([])
  const [mailUser, setMailUser] = useState("")
  const [buttonLoad, setbuttonLoad] = useState(false)
  const [buttonLoadBank, setbuttonLoadBank] = useState(false)
  const [buttonLoadWork, setbuttonLoadWork] = useState(false)
  const [buttonLoadEmail, setbuttonLoadEmail] = useState(false)
  const [buttonLoadSms, setbuttonLoadSms] = useState(false)
  const [buttonLoadNotif, setbuttonLoadNotif] = useState(false)
  const [buttonLoadNote, setbuttonLoadNote] = useState(false)
  const [buttonCharge, setbuttonCharge] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [mailBody, setMailBody] = useState("")
  const [smsUser, setSmsUser] = useState("")
  const [note, setNote] = useState("")
  const [userNotificationTitle, setUserNotificationTitle] = useState("")
  const [userNotificationMessage, setUserNotificationMessage] = useState("")
  const [chargeUser, setChargeUser] = useState("")
  const [loanType, setLoanType] = useState("")
  const [loanId, setLoanId] = useState("")
  const [error, setError] = useState("")
  const [singleLoan, setSingleLoan] = useState({})

  const showModal = singleLoan => {
    setSingleLoan(singleLoan)
  }

  // Update work status
  const updateWork = async work => {
    if (confirm("Are you sure you want to update the work status?")) {
      // do stuff
      setbuttonLoadWork(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_id: props?.match?.params?.id,
            status: work,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/update/work-details`,
          options
        )
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["data"])
          setbuttonLoadWork(false)
          setDisableButton(false)
          setTimeout(() => {
            location.reload()
          }, 5000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoadWork(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Update Bank status
  const updateBank = async bank => {
    if (confirm("Are you sure you want to update the bank status?")) {
      // do stuff
      setbuttonLoadBank(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_id: props?.match?.params?.id,
            status: bank,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/update/bank-details`,
          options
        )
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["data"])
          setbuttonLoadBank(false)
          setDisableButton(false)
          setTimeout(() => {
            location.reload()
          }, 5000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoadBank(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Update User status

  const updateStatus = async userStatus => {
    if (confirm("Are you sure you want to change user status?")) {
      // do stuff
      setbuttonLoad(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_id: props?.match?.params?.id,
            status: userStatus,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/update/user-status`,
          options
        )
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["message"])
          setbuttonLoad(false)
          setDisableButton(false)
          setTimeout(() => {
            location.reload()
          }, 5000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoad(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Send Email
  const sendEmail = async () => {
    if (confirm("Are you sure you want to send email to this user?")) {
      // do stuff
      setbuttonLoadEmail(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_id: props?.match?.params?.id,
            subject: mailBody,
            body: mailUser,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(`${baseURL}/api/admin/send-mail`, options)
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["message"])
          setbuttonLoadEmail(false)
          setDisableButton(false)
          setTimeout(() => {
            location.reload()
          }, 5000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoadEmail(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Send Push Notification to a user
  const sendNotification = async () => {
    if (confirm("Are you sure you want to send a notification to this user?")) {
      // do stuff
      setbuttonLoadNotif(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_id: props?.match?.params?.id,
            message: userNotificationMessage,
            title: userNotificationTitle,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/user/send-single-notification`,
          options
        )
        const data = await response.json()
        setError(data.errors)
        toast(data.message)
        setbuttonLoadNotif(false)
        setDisableButton(false)
        if (data.status === "Success") {
          setUserNotificationTitle("")
          setUserNotificationMessage("")
        }
      } catch (error) {
        console.log(error, "error message")
        // do what
      }
    } else {
      return false
    }
  }

  // Send SMS
  const sendSms = async () => {
    if (confirm("Are you sure you want to message this user?")) {
      // do stuff
      setbuttonLoadSms(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_id: props?.match?.params?.id,
            message: smsUser,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(`${baseURL}/api/admin/send-sms`, options)
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["message"])
          setbuttonLoadSms(false)
          setDisableButton(false)
          setTimeout(() => {
            location.reload()
          }, 5000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoadSms(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Send Note
  const addNote = async () => {
    if (confirm("Are you sure you want to add a note for this user?")) {
      // do stuff
      setbuttonLoadNote(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_id: props?.match?.params?.id,
            note,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/user/add-note`,
          options
        )
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["message"])
          setbuttonLoadNote(false)
          setDisableButton(false)
          setTimeout(() => {
            location.reload()
          }, 5000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoadNote(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Charge user
  const chargeUserAccount = async () => {
    if (chargeUser === "" || loanType === "") {
      toast.warn("select amount and type")
    } else if (confirm("Are you sure you want to charge this user?")) {
      // do stuff
      setbuttonCharge(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            loan_id: loanId,
            amount: chargeUser,
            type: loanType,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/loan/charge`,
          options
        )
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["message"])
          setbuttonCharge(false)
          setDisableButton(false)
          setTimeout(() => {
            location.reload()
          }, 5000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonCharge(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // change weekly fee status
  const updateWeeklyCharge = async (id, status) => {
      // do stuff
      setbuttonLoadNote(true)
      setDisableButton(true)
      try {
        const options = {
          method: "PUT",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            weekly_charge_id: id,
            status,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/charges/update/weekly-fee`,
          options
        )
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["message"])
          setbuttonLoadNote(false)
          setDisableButton(false)
          setTimeout(() => {
            location.reload()
          }, 5000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoadNote(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
  }

  // Audit Trail
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/user-audit/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        setLoading(false)
        const loan_id = profile.data.loans.map(loan => loan.id)
        setLoanId(loan_id[loan_id.length - 1])
        setProfile(profile.data)
        console.log(profile.data)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [])

  // Loan Limit
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            role: "admin",
            user_id: props?.match?.params?.id,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/loan/check-max-loan`,
          options
        )
        const profile = await response.json()
        setLoading(false)
        setLoanLimit(profile.data)
        if (response.status === 400) {
          setIncome(profile)
        }
      } catch (error) {
        console.log("error", error.message)
      }
    }

    fetchData()
  }, [])

  // User Invoice
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_id: props?.match?.params?.id,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/user/fetch-users-invoice-number`,
          options
        )
        const merchantNumber = await response.json()
        setLoading(false)
        setMerchantNumber(merchantNumber.data.length)
        if (response.status === 400) {
          setMerchantNumber(merchantNumber.data.length)
        }
      } catch (error) {
        console.log("error", error.message)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            user_id: props?.match?.params?.id,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/user/user-total-loan-amount`,
          options
        )
        const totalLoanAmount = await response.json()
        setLoading(false)
        setTotalLoanAmount(totalLoanAmount.data)
      } catch (error) {
        console.log("error", error.message)
      }
    }

    fetchData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Profile | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-success"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <h2 className="dashboard-head mb-4">User Information</h2>
                    </div>
                  )}

                  <Row>
                    <Col>
                      <div className="row feture-tabs" data-aos="fade-up">
                        <div className="col-lg-12">
                          <ul className="nav nav-pills mb-3">
                            <li>
                              <a
                                className="nav-link active"
                                data-bs-toggle="pill"
                                href="#tab1"
                              >
                                User Profile
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link"
                                data-bs-toggle="pill"
                                href="#tab2"
                              >
                                User Transaction
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link"
                                data-bs-toggle="pill"
                                href="#tab3"
                              >
                                Overview
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              className="tab-pane fade show active"
                              id="tab1"
                            >
                              <Card>
                                <CardBody>
                                  <Link
                                    to="/all-users"
                                    className="text-success"
                                  >
                                    <FaArrowLeft /> back
                                  </Link>
                                  <Row className="mt-4">
                                    <Col lg={4} className="display-name">
                                      <Row>
                                        <Col lg={12} className="mb-5">
                                          <CardImg
                                            src={profile?.user?.selfie_url}
                                            alt={profile?.user?.first_name}
                                            className="profile-image"
                                          />
                                        </Col>
                                        <Col lg={12} className="mb-5">
                                          <CardImg
                                            src={
                                              profile?.user
                                                ?.user_government_id_url
                                            }
                                            alt={profile?.user?.first_name}
                                            className="profile-image"
                                          />
                                          <a
                                            href={
                                              profile?.user
                                                ?.user_government_id_url
                                            }
                                            target="_blank"
                                            className="text-white"
                                          >
                                            <Row className="view float-end m-2">
                                              <Col lg={12}>View ID</Col>
                                            </Row>
                                          </a>
                                        </Col>
                                      </Row>
                                    </Col>

                                    <Col lg={6}>
                                      <h4 className="user-info my-4">
                                        PERSONAL INFORMATION
                                      </h4>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          First name
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.first_name}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Last name
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.last_name}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Email address
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="email"
                                            value={profile?.user?.email}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Phone number
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.phone}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Username
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.username}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Date of Birth
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.date_of_birth}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Gender
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.gender}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          User status
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.status}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Loan status
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.loan_status}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Address
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.address}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={6}>
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-success dropdown-toggle"
                                              type="button"
                                              id="dropdownMenuButton1"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              disabled={disableButton}
                                            >
                                              <span
                                                style={{ marginRight: "5px" }}
                                              >
                                                Change User Status
                                              </span>
                                              {buttonLoad && (
                                                <div
                                                  className="spinner-border text-light mx-3"
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                  role="status"
                                                >
                                                  <span className="visually-hidden">
                                                    Loading...
                                                  </span>
                                                </div>
                                              )}
                                            </button>
                                            <ul
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton1"
                                            >
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#0af09f",
                                                }}
                                                onClick={() => {
                                                  updateStatus("active")
                                                }}
                                              >
                                                active
                                              </li>
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#f7bc19",
                                                }}
                                                onClick={() => {
                                                  updateStatus("suspended")
                                                }}
                                              >
                                                suspended
                                              </li>
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#E33700",
                                                }}
                                                onClick={() => {
                                                  updateStatus("defaulter")
                                                }}
                                              >
                                                defaulted
                                              </li>
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#E3B100",
                                                }}
                                                onClick={() => {
                                                  updateStatus("pending")
                                                }}
                                              >
                                                pending
                                              </li>
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#0af09f",
                                                }}
                                                onClick={() => {
                                                  updateStatus("verified")
                                                }}
                                              >
                                                verified
                                              </li>
                                            </ul>
                                          </div>
                                        </Col>
                                      </Row>
                                      <h4 className="user-info my-4">
                                        LOAN LIMIT
                                      </h4>
                                      <Row>
                                        <Col lg={12}>
                                          {loanLimit ? (
                                            <div>
                                              <Row className="mb-4">
                                                <Col
                                                  lg={4}
                                                  className="display-name"
                                                >
                                                  Maximum Amount
                                                </Col>
                                                <Col lg={8}>
                                                  <CurrencyFormat
                                                    value={loanLimit?.max_amount_to_borrow?.toFixed(
                                                      2
                                                    )}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"₦"}
                                                    renderText={value => (
                                                      <>
                                                        <Input
                                                          className="input-form mb-3"
                                                          type="text"
                                                          defaultValue={value}
                                                        />
                                                      </>
                                                    )}
                                                  />
                                                </Col>
                                              </Row>
                                              <Row className="my-4">
                                                <Col
                                                  lg={4}
                                                  className="display-name"
                                                >
                                                  Maximum Withdrawal
                                                </Col>
                                                <Col lg={8}>
                                                  <CurrencyFormat
                                                    value={loanLimit?.max_withdraw_amount?.toFixed(
                                                      2
                                                    )}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"₦"}
                                                    renderText={value => (
                                                      <>
                                                        <Input
                                                          className="input-form mb-3"
                                                          type="text"
                                                          defaultValue={value}
                                                        />
                                                      </>
                                                    )}
                                                  />
                                                </Col>
                                              </Row>
                                            </div>
                                          ) : (
                                            income.message
                                          )}
                                        </Col>
                                      </Row>

                                      <h4 className="user-info my-4">WALLET</h4>
                                      <Row>
                                        <Col lg={12}>
                                          {loanLimit ? (
                                            <div>
                                              <Row className="mb-4">
                                                <Col
                                                  lg={4}
                                                  className="display-name"
                                                >
                                                  Wallet Amount
                                                </Col>
                                                <Col lg={8}>
                                                  <CurrencyFormat
                                                    value={profile?.user?.wallet?.amount?.toFixed(
                                                      2
                                                    )}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"₦"}
                                                    renderText={value => (
                                                      <>
                                                        <Input
                                                          className="input-form mb-3"
                                                          type="text"
                                                          defaultValue={value}
                                                        />
                                                      </>
                                                    )}
                                                  />
                                                </Col>
                                              </Row>
                                            </div>
                                          ) : (
                                            income.message
                                          )}
                                        </Col>
                                      </Row>

                                      <h4 className="user-info mt-5 mb-4">
                                        BANK DETAILS
                                      </h4>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Account name
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.bank_details
                                                ?.account_name
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Bank name
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.bank_details
                                                ?.bank_name
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Account number
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.bank_details
                                                ?.account_number
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Account status
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.bank_details
                                                ?.status
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Mono Account ID
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.bank_details
                                                ?.mono_account_id
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Updated Date
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.bank_details?.updated_at
                                              ?.toString()
                                              ?.replace(".000000Z", "")
                                              ?.replace("T", " ")}
                                          />
                                        </Col>
                                      </Row>
                                      <Col lg={6}>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-success dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            disabled={disableButton}
                                          >
                                            <span
                                              style={{ marginRight: "5px" }}
                                            >
                                              Update Bank Status
                                            </span>
                                            {buttonLoadBank && (
                                              <div
                                                className="spinner-border text-light mx-3"
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                }}
                                                role="status"
                                              >
                                                <span className="visually-hidden">
                                                  Loading...
                                                </span>
                                              </div>
                                            )}
                                          </button>
                                          <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                          >
                                            <li
                                              className="dropdown-item"
                                              style={{
                                                cursor: "pointer",
                                                color: "#E3B100",
                                              }}
                                              onClick={() => {
                                                updateBank("pending")
                                              }}
                                            >
                                              Pending
                                            </li>
                                            <li
                                              className="dropdown-item"
                                              style={{
                                                cursor: "pointer",
                                                color: "#f5c127",
                                              }}
                                              onClick={() => {
                                                updateBank("processing")
                                              }}
                                            >
                                              processing
                                            </li>
                                            <li
                                              className="dropdown-item"
                                              style={{
                                                cursor: "pointer",
                                                color: "#0af09f",
                                              }}
                                              onClick={() => {
                                                updateBank("verified")
                                              }}
                                            >
                                              verified
                                            </li>
                                          </ul>
                                        </div>
                                      </Col>

                                      <h4 className="user-info mt-5 mb-4">
                                        DEBIT CARD INFORMATION
                                      </h4>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Issuer
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.user?.card?.issuer}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          First six digits
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.card?.first_6digits
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Last four digits
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.card?.last_4digits
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Expiry date
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.card?.expiry_date
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <h4 className="user-info mt-5 mb-4">
                                        WORK DETAILS
                                      </h4>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Organization
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.work_details
                                                ?.organization
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Job title
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.work_details
                                                ?.job_title
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Work email address
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.work_details?.email
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Workplace address
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.user?.work_details
                                                ?.work_address
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Work status
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-5"
                                            type="text"
                                            value={
                                              profile?.user?.work_details
                                                ?.status
                                            }
                                          />
                                        </Col>
                                        <Col lg={6}>
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-success dropdown-toggle"
                                              type="button"
                                              id="dropdownMenuButton1"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              disabled={disableButton}
                                            >
                                              <span
                                                style={{ marginRight: "5px" }}
                                              >
                                                Update Work Status
                                              </span>
                                              {buttonLoadWork && (
                                                <div
                                                  className="spinner-border text-light mx-3"
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                  role="status"
                                                >
                                                  <span className="visually-hidden">
                                                    Loading...
                                                  </span>
                                                </div>
                                              )}
                                            </button>
                                            <ul
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton1"
                                            >
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#E3B100",
                                                }}
                                                onClick={() => {
                                                  updateWork("pending")
                                                }}
                                              >
                                                Pending
                                              </li>
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#0af09f",
                                                }}
                                                onClick={() => {
                                                  updateWork("confirmed")
                                                }}
                                              >
                                                confirmed
                                              </li>
                                            </ul>
                                          </div>
                                        </Col>
                                      </Row>

                                      <h4 className="user-info mt-5 mb-4">
                                        USER IDENTIFICATION
                                      </h4>

                                      {profile?.user?.user_identification ? (
                                        <Row>
                                          <Col lg={12} className="mb-4">
                                            <CardImg
                                              src={
                                                profile?.user
                                                  ?.user_identification?.link
                                              }
                                              alt={profile?.user?.username}
                                              className="profile-image"
                                            />
                                            <a
                                              href={
                                                profile?.user
                                                  ?.user_identification?.link
                                              }
                                              target="_blank"
                                              className="text-white"
                                            ></a>
                                          </Col>
                                          <Col lg={4} className="display-name">
                                            Date Created
                                          </Col>
                                          <Col lg={8}>
                                            <Input
                                              className="input-form mb-3"
                                              type="text"
                                              value={profile?.user?.user_identification?.created_at
                                                ?.toString()
                                                ?.replace(".000000Z", "")
                                                ?.replace("T", " ")}
                                            />
                                          </Col>
                                        </Row>
                                      ) : (
                                        "No Identification"
                                      )}

                                      <Row>
                                        <Col lg={4} className="display-name">
                                          <p>
                                            {" "}
                                            {profile?.user?.user_identification
                                              ?.link ? (
                                              <a
                                                target="_blank"
                                                href={
                                                  profile?.user
                                                    ?.user_identification?.link
                                                }
                                              >
                                                <img
                                                  className="img-fluid mb-5"
                                                  style={{ width: "500px" }}
                                                  src={
                                                    profile?.user
                                                      ?.user_identification
                                                      ?.link
                                                  }
                                                  alt={profile?.user?.username}
                                                />
                                              </a>
                                            ) : (
                                              "No Identification"
                                            )}
                                          </p>
                                          {profile?.user?.user_identification?.created_at
                                            ?.toString()
                                            ?.replace(".000000Z", "")
                                            ?.replace("T", " ")}
                                        </Col>
                                      </Row>
                                      <h4 className="user-info mt-5 mb-4">
                                        MAIL USER
                                      </h4>
                                      <Row>
                                        <Col lg={12}>
                                          <Row>
                                            <Col
                                              lg={12}
                                              className="display-name"
                                            >
                                              Title
                                            </Col>
                                            <Col lg={12}>
                                              <Input
                                                className="input-form mb-3"
                                                type="text"
                                                required
                                                value={mailBody}
                                                onChange={e =>
                                                  setMailBody(e.target.value)
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          <Col
                                            lg={12}
                                            className="display-name mb-3"
                                          >
                                            Body
                                          </Col>
                                          <Input
                                            type="textarea"
                                            name="email-user"
                                            className="input-form"
                                            required
                                            value={mailUser}
                                            onChange={e =>
                                              setMailUser(e.target.value)
                                            }
                                            style={{ height: "100px" }}
                                          />
                                          <button
                                            type="submit"
                                            className="btn btn-success mt-3"
                                            disabled={disableButton}
                                            onClick={() => sendEmail()}
                                          >
                                            send Email
                                            {buttonLoadEmail && (
                                              <div
                                                className="spinner-border text-light mx-3"
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                }}
                                                role="status"
                                              >
                                                <span className="visually-hidden">
                                                  Loading...
                                                </span>
                                              </div>
                                            )}
                                          </button>
                                        </Col>
                                      </Row>

                                      <h4 className="user-info mt-5 mb-4">
                                        SEND SMS
                                      </h4>
                                      <Row>
                                        <Col lg={12}>
                                          <Col
                                            lg={12}
                                            className="display-name mb-3"
                                          >
                                            Message
                                          </Col>
                                          <Input
                                            type="textarea"
                                            name="email-user"
                                            className="input-form"
                                            required
                                            value={smsUser}
                                            onChange={e =>
                                              setSmsUser(e.target.value)
                                            }
                                          />
                                          <button
                                            type="submit"
                                            className="btn btn-success mt-3"
                                            disabled={disableButton}
                                            onClick={() => sendSms()}
                                          >
                                            send sms
                                            {buttonLoadSms && (
                                              <div
                                                className="spinner-border text-light mx-3"
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                }}
                                                role="status"
                                              >
                                                <span className="visually-hidden">
                                                  Loading...
                                                </span>
                                              </div>
                                            )}
                                          </button>
                                        </Col>
                                      </Row>

                                      <h4 className="user-info mt-5 mb-4">
                                        SEND PUSH NOTIFICATION
                                      </h4>
                                      <Row>
                                        <Col lg={12}>
                                          <Row>
                                            <Col
                                              lg={12}
                                              className="display-name"
                                            >
                                              Title
                                            </Col>
                                            <Col lg={12}>
                                              <Input
                                                className="input-form mb-3"
                                                type="text"
                                                required
                                                value={userNotificationTitle}
                                                onChange={e =>
                                                  setUserNotificationTitle(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              <p className="text-danger">
                                                {error?.title}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Col
                                            lg={12}
                                            className="display-name mb-3"
                                          >
                                            Body
                                          </Col>
                                          <Input
                                            type="textarea"
                                            name="email-user"
                                            className="input-form"
                                            required
                                            value={userNotificationMessage}
                                            onChange={e =>
                                              setUserNotificationMessage(
                                                e.target.value
                                              )
                                            }
                                            style={{ height: "100px" }}
                                          />
                                          <p className="text-danger">
                                            {error?.message}
                                          </p>

                                          <button
                                            type="submit"
                                            className="btn btn-success mt-3"
                                            disabled={disableButton}
                                            onClick={() => sendNotification()}
                                          >
                                            send Notification
                                            {buttonLoadNotif && (
                                              <div
                                                className="spinner-border text-light mx-3"
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                }}
                                                role="status"
                                              >
                                                <span className="visually-hidden">
                                                  Loading...
                                                </span>
                                              </div>
                                            )}
                                          </button>
                                        </Col>
                                      </Row>

                                      <h4 className="user-info mt-5 mb-4">
                                        NOTES
                                      </h4>
                                      {profile?.user?.notes?.map(item => (
                                        <div key={item.id}>
                                          <h6 className="text-secondary">
                                            {item.created_at
                                              ?.toString()
                                              ?.replace("T", " ")
                                              ?.replace(".000000Z", "")}
                                          </h6>
                                          <p>{item.note}</p>
                                          <hr />
                                        </div>
                                      ))}
                                      <Row>
                                        <Col lg={12}>
                                          <Col
                                            lg={12}
                                            className="display-name mb-3"
                                          >
                                            Note
                                          </Col>
                                          <Input
                                            type="textarea"
                                            name="email-user"
                                            className="input-form"
                                            required
                                            value={note}
                                            onChange={e =>
                                              setNote(e.target.value)
                                            }
                                          />
                                          <button
                                            type="submit"
                                            className="btn btn-success mt-3"
                                            disabled={disableButton}
                                            onClick={() => addNote()}
                                          >
                                            Add Note
                                            {buttonLoadNote && (
                                              <div
                                                className="spinner-border text-light mx-3"
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                }}
                                                role="status"
                                              >
                                                <span className="visually-hidden">
                                                  Loading...
                                                </span>
                                              </div>
                                            )}
                                          </button>
                                        </Col>
                                      </Row>

                                      <h4 className="user-info mt-5 mb-4">
                                        CHARGE USER
                                      </h4>
                                      <Row>
                                        <Col lg={12}>
                                          <Col
                                            lg={12}
                                            className="display-name mb-3"
                                          >
                                            <label htmlFor="amount">
                                              Amount
                                            </label>
                                          </Col>
                                          <Input
                                            type="text"
                                            className="input-form mb-3"
                                            name="charge user"
                                            required
                                            min={1000}
                                            value={chargeUser}
                                            onChange={e => {
                                              const re = /^[0-9\b]+$/

                                              if (
                                                e.target.value === "" ||
                                                re.test(e.target.value)
                                              ) {
                                                setChargeUser(e.target.value)
                                              }
                                            }}
                                          />
                                          <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            defaultValue={loanType}
                                            onChange={e => {
                                              setLoanType(e.target.value)
                                            }}
                                            required
                                          >
                                            <option defaultValue>
                                              --Select Type--
                                            </option>
                                            <option value="loan_repayment">
                                              Loan Repayment
                                            </option>
                                            <option value="weekly_charges">
                                              Weekly Fee
                                            </option>
                                          </select>
                                          <button
                                            type="submit"
                                            className="btn btn-success mt-3"
                                            disabled={disableButton}
                                            onClick={() => chargeUserAccount()}
                                          >
                                            Charge User
                                            {buttonCharge && (
                                              <div
                                                className="spinner-border text-light mx-3"
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                }}
                                                role="status"
                                              >
                                                <span className="visually-hidden">
                                                  Loading...
                                                </span>
                                              </div>
                                            )}
                                          </button>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </div>

                            <div className="tab-pane fade show" id="tab2">
                              <Row>
                                <Col className="col-12">
                                  <Card>
                                    <CardBody>
                                      <h6 style={{ color: "#21CD9A" }}>
                                        LOAN DETAILS
                                      </h6>
                                      <div className="table-responsive">
                                        <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                                          <thead>
                                            <tr style={{ color: "#cccccc" }}>
                                              <th>DATE INITIATED</th>
                                              <th>DESCRIPTION</th>
                                              <th>DATE APPROVED</th>
                                              <th>AMOUNT</th>
                                              <th>DUE DATE</th>
                                              <th>OUTSTANDING BALANCE</th>
                                              <th>LOAN STATUS</th>
                                              <th>WEEKLY CHARGE</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {profile?.loans?.map(loan => {
                                              const {
                                                id,
                                                amount,
                                                created_at,
                                                approval_date,
                                                current_amount,
                                                description,
                                                due_date,
                                                weekly_charges,
                                                status,
                                              } = loan
                                              return (
                                                <tr key={id}>
                                                  <td>
                                                    {created_at
                                                      ?.toString()
                                                      ?.replace(".000000Z", "")
                                                      ?.replace("T", " ")}
                                                  </td>
                                                  <td>{description}</td>
                                                  <td>
                                                    {approval_date
                                                      ? approval_date
                                                      : "Not Approved yet"}
                                                  </td>
                                                  <CurrencyFormat
                                                    value={amount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"₦"}
                                                    renderText={value => (
                                                      <td>{value}</td>
                                                    )}
                                                  />
                                                  <td>
                                                    {due_date
                                                      ?.toString()
                                                      ?.replace(".000000Z", "")
                                                      ?.replace("T", " ")}
                                                  </td>
                                                  <CurrencyFormat
                                                    value={current_amount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"₦"}
                                                    renderText={value => (
                                                      <td>{value}</td>
                                                    )}
                                                  />
                                                  <td
                                                    style={{
                                                      color:
                                                        status === "paid-off"
                                                          ? "#00E324"
                                                          : status ===
                                                            "cancelled"
                                                          ? "#E33700"
                                                          : "#E3B100",
                                                    }}
                                                  >
                                                    {status}
                                                  </td>
                                                  <td>
                                                    <button
                                                      type="button"
                                                      className="badge bg-primary border-0 p-2"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#basicModal"
                                                      onClick={() => {
                                                        showModal(loan)
                                                      }}
                                                    >
                                                      View
                                                    </button>
                                                    <div
                                                      className="modal fade"
                                                      id="basicModal"
                                                      tabIndex="-1"
                                                    >
                                                      <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                          <div className="modal-header">
                                                            <h5 className="modal-title">
                                                              WEEKLY CHARGE
                                                            </h5>
                                                            <button
                                                              type="button"
                                                              className="btn-close"
                                                              data-bs-dismiss="modal"
                                                              aria-label="Close"
                                                            ></button>
                                                          </div>
                                                          <div className="modal-body">
                                                            <h6
                                                              style={{
                                                                color:
                                                                  "#21CD9A",
                                                              }}
                                                            >
                                                              WEEKLY CHARGE
                                                            </h6>
                                                            <div className="table-responsive">
                                                              <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                                                                <thead>
                                                                  <tr
                                                                    style={{
                                                                      color:
                                                                        "#cccccc",
                                                                    }}
                                                                  >
                                                                    <th>
                                                                      WEEK
                                                                    </th>
                                                                    <th>
                                                                      DATE
                                                                      INITIATED
                                                                    </th>
                                                                    <th>
                                                                      DATE
                                                                      CHARGED
                                                                    </th>
                                                                    <th>
                                                                      AMOUNT
                                                                    </th>
                                                                    <th>
                                                                      STATUS
                                                                    </th>
                                                                    <th>
                                                                      CHANGE STATUS
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {singleLoan?.weekly_charges?.map(
                                                                    charge => {
                                                                      const {
                                                                        id,
                                                                        amount,
                                                                        week,
                                                                        status,
                                                                        created_at,
                                                                        updated_at,
                                                                      } = charge
                                                                      return (
                                                                        <>
                                                                          <tr
                                                                            key={
                                                                              id
                                                                            }
                                                                          >
                                                                            <td>
                                                                              {
                                                                                week
                                                                              }
                                                                            </td>
                                                                            <td>
                                                                              {created_at
                                                                                ?.toString()
                                                                                ?.replace(
                                                                                  "T",
                                                                                  " "
                                                                                )
                                                                                ?.replace(
                                                                                  ".000000Z",
                                                                                  ""
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                              {updated_at
                                                                                ?.toString()
                                                                                ?.replace(
                                                                                  "T",
                                                                                  " "
                                                                                )
                                                                                ?.replace(
                                                                                  ".000000Z",
                                                                                  ""
                                                                                )}
                                                                            </td>
                                                                            <CurrencyFormat
                                                                              value={
                                                                                amount
                                                                              }
                                                                              displayType={
                                                                                "text"
                                                                              }
                                                                              thousandSeparator={
                                                                                true
                                                                              }
                                                                              prefix={
                                                                                "₦"
                                                                              }
                                                                              renderText={value => (
                                                                                <td>
                                                                                  {
                                                                                    value
                                                                                  }
                                                                                </td>
                                                                              )}
                                                                            />
                                                                            <td
                                                                              style={{
                                                                                color:
                                                                                  status ===
                                                                                  "paid"
                                                                                    ? "#00E324"
                                                                                    : status ===
                                                                                      "failed"
                                                                                    ? "#E33700"
                                                                                    : "#E3B100",
                                                                              }}
                                                                            >
                                                                              {
                                                                                status
                                                                              }
                                                                            </td>
                                                                            <td>
                                                                              <div className="dropdown">
                                                                                <button
                                                                                  className="badge border-0 p-2 bg-secondary dropdown-toggle"
                                                                                  type="button"
                                                                                  id="dropdownMenuButton1"
                                                                                  data-bs-toggle="dropdown"
                                                                                  aria-expanded="false"
                                                                                >
                                                                                  <FaSyncAlt />
                                                                                </button>
                                                                                <ul
                                                                                  className="dropdown-menu"
                                                                                  aria-labelledby="dropdownMenuButton1"
                                                                                >
                                                                                  <li
                                                                                    className="dropdown-item"
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                      color:
                                                                                        "#E3B100",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                      updateWeeklyCharge(
                                                                                        id,
                                                                                        "pending"
                                                                                      )
                                                                                    }}
                                                                                  >
                                                                                    Pending
                                                                                  </li>
                                                                                  <li
                                                                                    className="dropdown-item"
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                      color:
                                                                                        "#00E324",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                      updateWeeklyCharge(
                                                                                        id,
                                                                                        "paid"
                                                                                      )
                                                                                    }}
                                                                                  >
                                                                                    paid
                                                                                  </li>
                                                                                  <li
                                                                                    className="dropdown-item"
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                      color:
                                                                                        "#E33700",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                      updateWeeklyCharge(
                                                                                        id,
                                                                                        "failed"
                                                                                      )
                                                                                    }}
                                                                                  >
                                                                                    Failed
                                                                                  </li>
                                                                                </ul>
                                                                              </div>
                                                                            </td>
                                                                          </tr>
                                                                        </>
                                                                      )
                                                                    }
                                                                  )}
                                                                </tbody>
                                                              </Table>
                                                            </div>
                                                          </div>
                                                          <div className="modal-footer">
                                                            <button
                                                              type="button"
                                                              className="btn btn-secondary"
                                                              data-bs-dismiss="modal"
                                                            >
                                                              Close
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>

                              <Row>
                                <Col className="col-12">
                                  <Card>
                                    <CardBody>
                                      <h6 style={{ color: "#21CD9A" }}>
                                        USER TRANSACTION HISTORY
                                      </h6>
                                      <div className="table-responsive">
                                        <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                                          <thead>
                                            <tr style={{ color: "#cccccc" }}>
                                              <th>DATE INITIATED</th>
                                              <th>TRANSACTIOIN ID</th>
                                              <th>SENDER</th>
                                              <th>NARRATION</th>
                                              <th>RESPONSE</th>
                                              <th>TYPE</th>
                                              <th>METHOD</th>
                                              <th>AMOUNT</th>
                                              <th>STATUS</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {profile?.transactions?.map(
                                              transaction => {
                                                const {
                                                  id,
                                                  amount,
                                                  status,
                                                  reference,
                                                  method,
                                                  type,
                                                  narration,
                                                  created_at,
                                                  from,
                                                  response,
                                                } = transaction
                                                return (
                                                  <tr key={id}>
                                                    <td>
                                                      {" "}
                                                      {created_at
                                                        ?.toString()
                                                        ?.replace(
                                                          ".000000Z",
                                                          ""
                                                        )
                                                        ?.replace("T", " ")}
                                                    </td>
                                                    <td>{reference}</td>
                                                    <td>{from}</td>
                                                    <td>
                                                      {narration
                                                        ? narration
                                                        : "None"}
                                                    </td>
                                                    <td>
                                                      {response
                                                        ? response
                                                        : "None"}
                                                    </td>
                                                    <td>{type}</td>
                                                    <td>{method}</td>

                                                    <CurrencyFormat
                                                      value={amount}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix={"₦"}
                                                      renderText={value => (
                                                        <td>{value}</td>
                                                      )}
                                                    />
                                                    <td
                                                      style={{
                                                        color:
                                                          status ===
                                                          "successful"
                                                            ? "#00E324"
                                                            : status ===
                                                              "failed"
                                                            ? "#E33700"
                                                            : "#E3B100",
                                                      }}
                                                    >
                                                      {status}
                                                    </td>
                                                  </tr>
                                                )
                                              }
                                            )}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </div>

                            <div className="tab-pane fade show" id="tab3">
                              <Row>
                                <Col>
                                  <Card body className="card-height">
                                    <img
                                      src={pallys}
                                      alt="pallys"
                                      className="image-icon"
                                    />
                                    <p className="user-head">
                                      Total Loans Requested
                                    </p>
                                    <h2 className="user-number">
                                      {profile?.loans?.length}
                                    </h2>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card body className="card-height">
                                    <img
                                      src={cash}
                                      alt="cas"
                                      className="image-icon"
                                    />
                                    <p className="user-head">
                                      Total Loan Amount Requested
                                    </p>
                                    <h2 className="user-number">
                                      <CurrencyFormat
                                        value={
                                          totalLoanAmount?.total_loans_amount
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"₦"}
                                        renderText={value => <p>{value}</p>}
                                      />
                                    </h2>
                                    <br />
                                  </Card>
                                </Col>
                                <Col>
                                  <Card body className="card-height">
                                    <img
                                      src={auto}
                                      alt="auto"
                                      className="image-icon"
                                    />
                                    <p className="user-head">Merchants</p>
                                    <h2 className="user-number">
                                      {merchantNumber}
                                    </h2>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UsersProfile
