import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { FaHome, FaMoneyBill, FaMoneyCheckAlt, FaSyncAlt, FaUsers, FaMoneyCheck, FaStoreAlt, FaShoppingCart, FaBell } from 'react-icons/fa'

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <FaHome style={{ marginRight: "10px" }} />
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaUsers style={{ marginRight: "10px" }} />
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/all-users">{props.t("All Users")}</Link>
                </li>
                <li>
                  <Link to="/active-users">{props.t("Active Users")}</Link>
                </li>
                <li>
                  <Link to="/spending-limit">
                    {props.t("Users With Spending")}
                  </Link>
                </li>
                <li>
                  <Link to="/inactive-users">{props.t("Pending Users")}</Link>
                </li>
                <li>
                  <Link to="/pending-users-work-details">
                    {props.t("Pending Work Details")}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/available-cards">
                    {props.t("Available Cards")}{" "}
                  </Link>
                </li> */}
                <li>
                  <Link to="/all-debtors">{props.t("All Debtors")} </Link>
                </li>
                <li>
                  <Link to="/defaulters">{props.t("All Defaulters")} </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaSyncAlt style={{ marginRight: "10px" }} />
                <span>{props.t("Transactions")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/all-transactions">
                    {props.t("All Transactions")}
                  </Link>
                </li>
                <li>
                  <Link to="/bank-transactions">
                    {props.t("Bank Transactions")}
                  </Link>
                </li>
                <li>
                  <Link to="/credit-transactions">
                    {props.t("Credit Transactions")}
                  </Link>
                </li>
                <li>
                  <Link to="/Debit-transactions">
                    {props.t("Debit Transactions")}
                  </Link>
                </li>
                <li>
                  <Link to="/pending-transactions">
                    {props.t("Pending Transactions")}
                  </Link>
                </li>
                <li>
                  <Link to="/successful-transactions">
                    {props.t("Successful Transactions")}
                  </Link>
                </li>
                <li>
                  <Link to="/wallet-transactions">
                    {props.t("Wallet Transactions")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaMoneyBill style={{ marginRight: "10px" }} />
                <span>{props.t("Loans")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/all-loans">{props.t("All Loans")}</Link>
                </li>
                <li>
                  <Link to="/pending-loans">{props.t("Pending Loans")}</Link>
                </li>
                <li>
                  <Link to="/disbursed-loans">
                    {props.t("Disbursed Loans")}
                  </Link>
                </li>
                <li>
                  <Link to="/cancelled-loans">
                    {props.t("Cancelled Loans")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaMoneyCheck style={{ marginRight: "10px" }} />
                <span>{props.t("Repayments")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/successful-repayments">
                    {props.t("Successful Repayments")}
                  </Link>
                </li>
                <li>
                  <Link to="/pending-repayments">
                    {props.t("Pending Repayments")}
                  </Link>
                </li>
                <li>
                  <Link to="/declined-repayments">
                    {props.t("Declined Repayments")}
                  </Link>
                </li>
                <li>
                  <Link to="/failed-repayments">
                    {props.t("Failed Repayments")}
                  </Link>
                </li>
                <li>
                  <Link to="/web-repayments">{props.t("Web Repayments")}</Link>
                </li>
                <li>
                  <Link to="/mobile-repayments">
                    {props.t("Mobile Repayments")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaStoreAlt style={{ marginRight: "10px" }} />
                <span>{props.t("Merchants")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/all-companies">{props.t("All Merchants")}</Link>
                </li>
                <li>
                  <Link to="/pending-companies">
                    {props.t("Pending Merchants")}
                  </Link>
                </li>
                <li>
                  <Link to="/activated-companies">
                    {props.t("Activated Merchants")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaShoppingCart style={{ marginRight: "10px" }} />
                <span>{props.t("Merchandise")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/merchandise">{props.t("Merchandise")}</Link>
                </li>
                <li>
                  <Link to="/completed-merchandise">
                    {props.t("Completed Merchandise")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaMoneyCheckAlt style={{ marginRight: "10px" }} />
                <span>{props.t("Charge")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/weekly-charges">{props.t("Weekly Charges")}</Link>
                </li>
                <li>
                  <Link to="/weekly-fee-defaulters">{props.t("Weekly Fee Defaulters")}</Link>
                </li>
                <li>
                  <Link to="/charge-history">{props.t("Charge History")}</Link>
                </li>
                {/* <li>
                  <Link to="/loan-payment">{props.t("Loan Payment")}</Link>
                </li> */}
              </ul>
            </li>
            <li>
              <Link to="/notifications">
                <FaBell style={{ marginRight: "10px" }} />
                <span>{props.t("Notifications")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
