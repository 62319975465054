import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, CardTitle } from "reactstrap"

import "../Users/datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import CurrencyFormat from "react-currency-format"
import { Redirect } from "react-router-dom"

const ChargeHistory = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/charges/weekly-charge-history`,
          options
        )
        const users = await response.json()
        setUsers(users.data)
        setLoading(false)
        console.log(users)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Weekly Charge History | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
                    Weekly Charge History
                  </h4>
                  {loading ? (
                    <div className="container">
                      <div className="row mt-4">
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      {users?.length !== 0 ? (
                        <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                          <thead>
                            <tr className="mt-5" style={{ color: "#cccccc" }}>
                              <th>FIRST NAME</th>
                              <th>LAST NAME</th>
                              <th>WEEKLY AMOUNT</th>
                              <th>WEEK</th>
                              <th>OUTSTANDING BALANCE</th>
                              <th>APPROVAL DATE</th>
                              <th>DUE DATE</th>
                              <th>STATUS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users
                              ?.filter(val => {
                                if (search === "") {
                                  return val
                                } else if (
                                  val?.user?.first_name
                                    ?.toLowerCase()
                                    ?.includes(search.toLowerCase())
                                ) {
                                  return val
                                } else if (
                                  val?.status
                                    ?.toLowerCase()
                                    ?.includes(search?.toLowerCase())
                                ) {
                                  return val
                                } else if (
                                  val?.user?.last_name
                                    ?.toLowerCase()
                                    ?.includes(search?.toLowerCase())
                                ) {
                                  return val
                                }
                              })
                              ?.map((merch, index) => {
                                const { amount, status, loan, week } = merch
                                return (
                                  <tr
                                    key={loan?.id}
                                    onClick={() =>
                                      setRedirect("/user/" + loan?.user_id)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td>{loan?.user.first_name}</td>
                                    <td>{loan?.user.last_name}</td>
                                    <CurrencyFormat
                                      value={amount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      renderText={value => <td>{value}</td>}
                                    />
                                    <td>{week}</td>
                                    <CurrencyFormat
                                      value={loan?.current_amount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      renderText={value => <td>{value}</td>}
                                    />
                                    <td>
                                      {loan?.approval_date
                                        ?.replace("T", " ")
                                        ?.replace(".000000Z", "")}
                                    </td>
                                    <td>
                                      {loan?.due_date
                                        ?.replace("T", " ")
                                        ?.replace(".000000Z", "")
                                        ?.replace("00:00:00", "")}
                                    </td>
                                    <td
                                      style={{
                                        color:
                                          status === "paid"
                                            ? "#00E324"
                                            : status === "cancelled"
                                            ? "#E33700"
                                            : "#E3B100",
                                      }}
                                    >
                                      {status}
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-5">
                          <h4 style={{ color: "#02a499" }}>No Charge Today</h4>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChargeHistory
