import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import { Table, Row, Col, Card, CardBody, CardTitle, Modal } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import CurrencyFormat from "react-currency-format"

const WebRepayments = () => {
  const [webRepayments, setWebRepayments] = useState([])
  const [loading, setLoading] = useState(true)
  const [modal_standard, setmodal_standard] = useState(false)
  const [repay, setRepay] = useState({})

  function tog_standard(repay) {
    setRepay(repay)
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const showModal = repay => {
    setRepay(repay)
  }

  const getWebRepayments = async () => {
    const options = {
      method: "GET",
      headers: new Headers({ Authorization: accessToken }),
    }
    const baseURL = process.env.REACT_APP_BASEURL
    const response = await fetch(
      `${baseURL}/api/admin/loan/web-repayment`,
      options
    )
    const webRepayments = await response.json()
    setWebRepayments(webRepayments.data)
    setLoading(false)
    console.log(webRepayments.data)
  }

  useEffect(() => {
    getWebRepayments()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Web Repayment | Cashpally Admin</title>
        </MetaTags>
        <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
          Web Repayments
        </h4>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-success"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>#</th>
                            <th>FULL NAME</th>
                            <th>PHONE</th>
                            <th>EMAIL</th>
                            <th>AMOUNT</th>
                            <th>REFERENCE</th>
                            <th>TYPE</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {webRepayments.map((transaction, index) => {
                            const {
                              id,
                              amount,
                              status,
                              type,
                              full_name,
                              phone,
                              email,
                              user,
                              ref,
                              loan,
                              created_at,
                            } = transaction
                            return (
                              <tr key={id}>
                                <td>{index + 1}</td>
                                <td>{full_name}</td>
                                <td>{phone}</td>
                                <td>{email}</td>
                                <CurrencyFormat
                                  value={amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />
                                <td>{ref}</td>

                                <td>{type}</td>

                                <td
                                  style={{
                                    color:
                                      status === "successful"
                                        ? "#00E324"
                                        : status === "failed"
                                        ? "#E33700"
                                        : "#E3B100",
                                  }}
                                >
                                  {status}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="badge bg-primary border-0 p-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#basicModal"
                                    onClick={() => {
                                      showModal(transaction)
                                    }}
                                  >
                                    View
                                  </button>
                                  <div
                                    class="modal fade"
                                    id="basicModal"
                                    tabindex="-1"
                                  >
                                    <div class="modal-dialog modal-lg">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <h5 class="modal-title">
                                            Repayment Details
                                          </h5>
                                          <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                        <div class="modal-body">
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Repayment Information
                                            </h5>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Amount:</strong>{" "}
                                                <CurrencyFormat
                                                  value={repay?.amount}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"₦"}
                                                  renderText={value => (
                                                    <span>{value}</span>
                                                  )}
                                                />
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Reference:</strong>{" "}
                                                {repay?.ref}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Repayment Date:</strong>{" "}
                                                {repay?.created_at
                                                  ?.replace("T", " ")
                                                  ?.replace(".000000Z", "")}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Repayment Type:</strong>{" "}
                                                {repay?.type}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>
                                                  Repayment Status:
                                                </strong>{" "}
                                                {repay?.status}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Loan Information
                                            </h5>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Amount:</strong>{" "}
                                                <CurrencyFormat
                                                  value={repay?.loan?.amount}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"₦"}
                                                  renderText={value => (
                                                    <span>{value}</span>
                                                  )}
                                                />
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Description:</strong>{" "}
                                                {repay?.loan?.description}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Request Date:</strong>{" "}
                                                {repay?.loan?.created_at
                                                  ?.replace("T", " ")
                                                  ?.replace(".000000Z", "")}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Approval Date:</strong>{" "}
                                                {repay?.loan?.approval_date
                                                  ? repay?.loan?.approval_date
                                                  : "Not yet approved"}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Due Date:</strong>{" "}
                                                {repay?.loan?.due_date
                                                  ? repay?.loan?.due_date
                                                  : "No due date"}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Repayment Date:</strong>{" "}
                                                {repay?.loan?.repayment_date
                                                  ? repay?.loan?.repayment_date
                                                  : "null"}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Borrower Name:</strong>{" "}
                                                {repay?.loan?.borrower_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Current Amount:</strong>{" "}
                                                <CurrencyFormat
                                                  value={
                                                    repay?.loan?.current_amount
                                                  }
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"₦"}
                                                  renderText={value => (
                                                    <span>{value}</span>
                                                  )}
                                                />
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Initial Amount:</strong>{" "}
                                                <CurrencyFormat
                                                  value={
                                                    repay?.loan?.initial_amount
                                                  }
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"₦"}
                                                  renderText={value => (
                                                    <span>{value}</span>
                                                  )}
                                                />
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Due Date:</strong>{" "}
                                                {repay?.loan?.due_date
                                                  ?.replace("T", " ")
                                                  ?.replace(".000000Z", "")
                                                  ?.replace("00:00:00", "")}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Total Vouchers:</strong>{" "}
                                                {repay?.loan?.total_vouchers}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>
                                                  Already Vouched:
                                                </strong>{" "}
                                                {repay?.loan?.already_vouched}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Loan Status:</strong>{" "}
                                                {repay?.loan?.status}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              User Information
                                            </h5>
                                            <Col lg={6}>
                                              <p>
                                                <strong>First Name:</strong>{" "}
                                                {repay?.user?.first_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Last Name:</strong>{" "}
                                                {repay?.user?.last_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Email:</strong>{" "}
                                                {repay?.user?.email}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Username:</strong>{" "}
                                                {repay?.user?.username}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Phone:</strong>{" "}
                                                {repay?.user?.phone}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Gender:</strong>{" "}
                                                {repay?.user?.gender}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Date of Birth:</strong>{" "}
                                                {repay?.user?.date_of_birth}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>User Status:</strong>{" "}
                                                {repay?.user?.status}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Loan Status:</strong>{" "}
                                                {repay?.user?.loan_status}
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div class="modal-footer">
                                          <button
                                            type="button"
                                            class="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                          >
                                            Close
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default WebRepayments
