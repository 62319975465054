import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, Redirect } from "react-router-dom"
import { Col, Container, Row, Card } from "reactstrap"
import accessToken from "helpers/jwt-token-access/accessToken"
import pallys from "../../assets/images/pallys.png"
import auto from "../../assets/images/auto-partners.png"
import cash from "../../assets/images/cash.png"
import "./index.css"
import { Doughnut, Line, defaults } from "react-chartjs-2"
import CurrencyFormat from "react-currency-format"
import { toast, ToastContainer } from "react-toastify"


const Dashboard = () => {
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }
  const [total, setTotal] = useState({})
  const [borrowCapicity, setBorrowCapacity] = useState('')
  const [redirect, setRedirect] = useState("")
  const [defaulter, setDefaulter] = useState("")

  const getTotal = async () => {
    try {
      const options = {
        method: "GET",
        headers: new Headers({ Authorization: accessToken }),
      }
      const baseURL = process.env.REACT_APP_BASEURL
      const response = await fetch(`${baseURL}/api/admin/profile`, options)
      const total = await response.json()
      console.log(total.data)
      setTotal(total.data)
      if(response.status  === 401) {
        setRedirect('/login')
      }
    } catch (error) {
      // do what
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getTotal()
  }, [])

  const getToalBorrowCapacity = async () => {
    const options = {
      method: "GET",
      headers: new Headers({ Authorization: accessToken }),
    }
    const baseURL = process.env.REACT_APP_BASEURL
    const response = await fetch(`${baseURL}/api/admin/total-borrow-capacity`, options)
    const borrowCapicity = await response.json()
    setBorrowCapacity(borrowCapicity.data?.toFixed(2))
  }

  useEffect(() => {
    getToalBorrowCapacity()
  }, [])


  if(redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cashpally | Admin Dashboard</title>
        </MetaTags>
        <Container fluid>
          <ToastContainer />
          <div className="page-title-box">
            <Row className="align-items-center mb-5">
              <Col md={8}>
                <h2 className="dashboard-head">Dashboard Home</h2>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card body className="card-height">
                  <Link className="text-success" to="/all-users">
                    <img src={pallys} alt="pallys" />
                    <p className="user-head">All Users</p>
                    <h2 className="user-number">
                      <CurrencyFormat
                        value={total?.all_user}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                        renderText={value => <>{value}</>}
                      />
                    </h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link className="text-success" to="/all-companies">
                    <img src={auto} alt="pallys" />
                    <p className="user-head">Merchants</p>
                    <h2 className="user-number">
                      <CurrencyFormat
                        value={total?.company}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                        renderText={value => <>{value}</>}
                      />
                    </h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link className="text-success" to="/disbursed-loans">
                    <img src={cash} alt="cash" />
                    <p className="user-head">Total Disbursed Loan Amount</p>
                    <h2 className="user-number">₦ {total?.debt}</h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link className="text-success" to="/pending-loans">
                    <img src={cash} alt="loan" />
                    <p className="user-head">Total Pending Loan Amount</p>
                    <h2 className="user-number">₦ {total?.pending_loan}</h2>
                  </Link>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card body className="card-height">
                  <Link className="text-success" to="/all-loans">
                    <img src={cash} alt="pallys" />
                    <p className="user-head">Total Loan Amount Requested</p>
                    <h2 className="user-number">₦ {total?.all_loan}</h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link className="text-success" to="/">
                    <img src={cash} alt="pallys" />
                    <p className="user-head">Total Users Borrow Capacity</p>
                    <h2 className="user-number">
                      <CurrencyFormat
                        value={borrowCapicity}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                        renderText={value => <>{value}</>}
                      />
                    </h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link className="text-success" to="/defaulters">
                    <img src={cash} alt="loan" />
                    <p className="user-head">Total Defaulters</p>
                    <h2 className="user-number">{total?.defaulter}</h2>
                  </Link>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Card body className="card-line-height">
                  <Row>
                    <Col lg={12}>
                      <h4 className="loan-statics">LOAN STATISTICS</h4>
                      <p className="loan-disbursed">Loan amount disbursed</p>
                    </Col>
                    <Col lg={12}>
                      <Line
                        data={{
                          labels: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                          ],
                          datasets: [
                            {
                              label: "LOAN STATISTICS",
                              data: [
                                100000, 200000, 150000, 400000, 350000, 603202,
                                480000,
                              ],
                              backgroundColor: ["rgba(33, 205, 154, 0.59)"],
                              borderColor: ["#21CD9A"],
                              borderWidth: 3,
                            },
                          ],
                        }}
                        height={400}
                        width={600}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                  stepSize: 200000,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  drawBorder: false,
                                  display: false,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={6}>
                <Row>
                  <Col lg={12}>
                    <Card body className="card-main">
                      <Link className="text-success" to="/all-users">
                        <h6 className="loan-statics">USER STATISTICS</h6>
                        <Row>
                          <Col lg={6}>
                            <Doughnut
                              data={{
                                // labels: [
                                //   "Default Users",
                                //   "Inactive Users",
                                //   "Active Users",
                                // ],
                                datasets: [
                                  {
                                    label: "LOAN STATISTICS",
                                    data: [100000, 50000, 150000],
                                    backgroundColor: [
                                      "#EC2121",
                                      "#17A101",
                                      "#FAB400",
                                    ],
                                    borderColor: ["transparent"],
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                              height={200}
                              width={300}
                              options={{
                                maintainAspectRatio: false,
                                cutoutPercentage: 80,
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            <div className="users">
                              <ul className="user-loans">
                                <li style={{ color: "#EC2121" }}>
                                  <span className="text-dark">
                                    Users with defaulting loans
                                  </span>
                                </li>
                                <small>(no loan or repaid loan)</small>
                                <li style={{ color: "#17A101" }}>
                                  <span className="text-dark">
                                    Users with inactive loans
                                  </span>
                                </li>
                                <li style={{ color: "#FAB400" }}>
                                  <span className="text-dark">
                                    Users with active loans
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Link>
                    </Card>
                  </Col>
                  <Col lg={12}>
                    <Card body className="card-main">
                      <Link className="text-success" to="/all-users">
                        <h6 className="loan-statics">MERCHANTS STATISTICS</h6>
                        <Row>
                          <Col lg={6}>
                            <Doughnut
                              data={{
                                // labels: [
                                //   "Default Merchants",
                                //   "Engaged Merchants",
                                //   "Active Merchants",
                                // ],
                                datasets: [
                                  {
                                    label: "LOAN STATISTICS",
                                    data: [50, 300, 234],
                                    backgroundColor: [
                                      "#EC2121",
                                      "#17A101",
                                      "#FAB400",
                                    ],
                                    borderColor: ["transparent"],
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                              height={200}
                              width={300}
                              options={{
                                maintainAspectRatio: false,
                                cutoutPercentage: 80,
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            <div className="users">
                              <ul className="user-loans">
                                <li style={{ color: "#EC2121" }}>
                                  <span className="text-dark">
                                    Deactivated Merchants
                                  </span>
                                </li>
                                <li style={{ color: "#17A101" }}>
                                  <span className="text-dark">
                                    Active Merchants
                                  </span>
                                </li>
                                <li style={{ color: "#FAB400" }}>
                                  <span className="text-dark">
                                    Engaged Merchants
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Link>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
