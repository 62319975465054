import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, CardImg, Input } from "reactstrap"

import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import CurrencyFormat from "react-currency-format"
import "../Users/UserProfile.css"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import { FaArrowLeft } from "react-icons/fa"

const MerchantProfile = props => {
  const [profile, setProfile] = useState([])
  const [loading, setLoading] = useState(true)
  const [buttonLoad, setbuttonLoad] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  // Audit Trail
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/user-audit/${props.match.params.id}`,
          options
        )
        const profile = await response.json()
        setLoading(false)
        console.log(profile.data, "profile")
        setProfile(profile.data)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [])

  // suspend merchant...
  const suspendMerchant = async id => {
    console.log(id)
    if (confirm("Are you sure you want to suspend merchant?")) {
      // do stuff
      setbuttonLoad(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ company_id: id }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/suspend-merchant`,
          options
        )
        const data = await response.json()
        // console.log(data)
        // Alert message
        toast.success(data.message)
        if (data.status == "Success") {
          setbuttonLoad(false)
          setDisableButton(false)
          toast.success(data["data"])
          //reload page
          setTimeout(function () {
            window.location.reload(1)
          }, 3000)
        }
      } catch (error) {
        // do what
        console.log(error)
      }
    } else {
      return false
    }
  }

  // activate merchant...
  const activateMerchant = async id => {
    if (confirm("Are you sure you want to activate the Merchant?")) {
      // do stuff
      setbuttonLoad(true)
      setDisableButton(true)
      try {
        const options = {
          method: "PUT",
          headers: new Headers({
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ company_id: id }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/company/activate`,
          options
        )
        const data = await response.json()
        // console.log(data)
        // Alert message
        if (data.status == "Success") {
          toast.success(data.message)
          setbuttonLoad(false)
          setDisableButton(true)
          //reload page
          setTimeout(function () {
            window.location.reload(1)
          }, 3000)
        }
      } catch (error) {
        console.log(error)
        setbuttonLoad(false)
        setDisableButton(false)
        // do what
      }
    } else {
      return false
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Profile | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-success"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <h2 className="dashboard-head mb-4">
                        Merchant Information
                      </h2>
                    </div>
                  )}

                  <Row>
                    <Col>
                      <div className="row feture-tabs" data-aos="fade-up">
                        <div className="col-lg-12">
                          <ul className="nav nav-pills mb-3">
                            <li>
                              <a
                                className="nav-link active"
                                data-bs-toggle="pill"
                                href="#tab1"
                              >
                                Merchant Profile
                              </a>
                            </li>
                            <li>
                              <a
                                className="nav-link"
                                data-bs-toggle="pill"
                                href="#tab2"
                              >
                                Merchant Transaction
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              className="tab-pane fade show active"
                              id="tab1"
                            >
                              <Card>
                                <CardBody>
                                  <Link
                                    to="/all-companies"
                                    className="text-success"
                                  >
                                    <FaArrowLeft /> back
                                  </Link>
                                  <Row className="mt-4">
                                    <Col lg={4} className="display-name">
                                      <Row>
                                        <Col lg={12} className="mb-5">
                                          <CardImg
                                            src={profile?.document?.CAC}
                                            alt={profile?.company?.first_name}
                                            className="profile-image"
                                          />
                                        </Col>
                                        <Col lg={12} className="mb-5">
                                          <CardImg
                                            src={profile?.document?.id_card}
                                            alt={profile?.company?.first_name}
                                            className="profile-image"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col lg={6}>
                                      <h4 className="user-info my-4">
                                        MERCHANT INFORMATION
                                      </h4>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Company name
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.company?.company_name
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Email address
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="email"
                                            value={
                                              profile?.company?.company_email
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Phone number
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.company?.phone}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Address
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.company?.company_address
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          City
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.contact_person?.city
                                            }
                                          />
                                        </Col>
                                        <Row>
                                          <Col lg={4} className="display-name">
                                            State
                                          </Col>
                                          <Col lg={8}>
                                            <Input
                                              className="input-form mb-3"
                                              type="text"
                                              value={
                                                profile?.contact_person?.state
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Services Offered
                                        </Col>
                                        <Col lg={8}>
                                          {profile?.company?.services.map(
                                            service => (
                                              <Input
                                                className="input-form mb-3"
                                                type="text"
                                                value={service.services}
                                              />
                                            )
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Activation status
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.company?.status}
                                          />
                                          <Col lg={6}>
                                            {profile?.company?.status ===
                                            "activated" ? (
                                              <button
                                                className="btn btn-danger my-2 mb-4"
                                                disabled={disableButton}
                                                onClick={() =>
                                                  suspendMerchant(
                                                    profile?.company?.id
                                                  )
                                                }
                                              >
                                                Suspend
                                                {buttonLoad && (
                                                  <div
                                                    className="spinner-border text-light mx-2"
                                                    style={{
                                                      width: "16px",
                                                      height: "16px",
                                                    }}
                                                    role="status"
                                                  >
                                                    <span className="visually-hidden">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                )}
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-success my-2 mb-4"
                                                disabled={disableButton}
                                                onClick={() =>
                                                  activateMerchant(
                                                    profile?.company?.id
                                                  )
                                                }
                                              >
                                                Activate
                                                {buttonLoad && (
                                                  <div
                                                    className="spinner-border text-light mx-2"
                                                    style={{
                                                      width: "16px",
                                                      height: "16px",
                                                    }}
                                                    role="status"
                                                  >
                                                    <span className="visually-hidden">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                )}
                                              </button>
                                            )}
                                          </Col>
                                        </Col>
                                      </Row>

                                      <h4 className="user-info my-4">
                                        CONTACT INFORMATION
                                      </h4>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          First name
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.contact_person
                                                ?.first_name
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Last name
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.contact_person
                                                ?.first_name
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Title or Role
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="email"
                                            value={
                                              profile?.contact_person?.title
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Email address
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.contact_person?.email
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Phone number
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.contact_person?.phone
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Date of birth
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.contact_person?.DOB}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Gender
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.contact_person?.gender
                                            }
                                          />
                                        </Col>
                                      </Row>

                                      {/* <h4 className="user-info mt-5 mb-4">
                                        BANK DETAILS
                                      </h4>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Account name
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.company?.bank_details
                                                ?.account_name
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Bank name
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.company?.bank_details
                                                ?.bank_name
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Account number
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.company?.bank_details
                                                ?.account_number
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Account status
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.company?.bank_details
                                                ?.status
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Mono Account ID
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={
                                              profile?.company?.bank_details
                                                ?.mono_account_id
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          Updated Date
                                        </Col>
                                        <Col lg={8}>
                                          <Input
                                            className="input-form mb-3"
                                            type="text"
                                            value={profile?.company?.bank_details?.updated_at
                                              ?.toString()
                                              ?.replace(".000000Z", "")
                                              ?.replace("T", " ")}
                                          />
                                        </Col>
                                      </Row> */}

                                      <h4 className="user-info mt-5 mb-4">
                                        REGISTRATION DOCUMENT
                                      </h4>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          CAC
                                        </Col>
                                        <Col lg={8}>
                                          <a
                                            href={profile?.document?.CAC}
                                            target="_blank"
                                            className="text-white"
                                          >
                                            <Row className="btn btn-dark w-50 float-end m-2">
                                              <Col lg={12}>View CAC</Col>
                                            </Row>
                                          </a>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} className="display-name">
                                          ID Card
                                        </Col>
                                        <Col lg={8}>
                                          <a
                                            href={profile?.document?.id_card}
                                            target="_blank"
                                            className="text-white"
                                          >
                                            <Row className="btn btn-dark w-50 float-end m-2">
                                              <Col lg={12}>View ID</Col>
                                            </Row>
                                          </a>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </div>

                            <div className="tab-pane fade show" id="tab2">
                              <Row>
                                <Col className="col-12">
                                  <Card>
                                    <CardBody>
                                      <h6 style={{ color: "#21CD9A" }}>
                                        MERCHANT TRANSACTION HISTORY
                                      </h6>
                                      <div className="table-responsive">
                                        <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                                          <thead>
                                            <tr style={{ color: "#cccccc" }}>
                                              <th>DATE INITIATED</th>
                                              <th>TRANSACTIOIN ID</th>
                                              <th>SENDER</th>
                                              <th>NARRATION</th>
                                              <th>RESPONSE</th>
                                              <th>TYPE</th>
                                              <th>METHOD</th>
                                              <th>AMOUNT</th>
                                              <th>STATUS</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {profile?.transactions?.map(
                                              transaction => {
                                                const {
                                                  id,
                                                  amount,
                                                  status,
                                                  reference,
                                                  method,
                                                  type,
                                                  narration,
                                                  created_at,
                                                  from,
                                                  response,
                                                } = transaction
                                                return (
                                                  <tr key={id}>
                                                    <td>
                                                      {" "}
                                                      {created_at
                                                        ?.toString()
                                                        ?.replace(
                                                          ".000000Z",
                                                          ""
                                                        )
                                                        ?.replace("T", " ")}
                                                    </td>
                                                    <td>{reference}</td>
                                                    <td>{from}</td>
                                                    <td>
                                                      {narration
                                                        ? narration
                                                        : "None"}
                                                    </td>
                                                    <td>
                                                      {response
                                                        ? response
                                                        : "None"}
                                                    </td>
                                                    <td>{type}</td>
                                                    <td>{method}</td>

                                                    <CurrencyFormat
                                                      value={amount}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix={"₦"}
                                                      renderText={value => (
                                                        <td>{value}</td>
                                                      )}
                                                    />
                                                    <td
                                                      style={{
                                                        color:
                                                          status ===
                                                          "successful"
                                                            ? "#00E324"
                                                            : status ===
                                                              "failed"
                                                            ? "#E33700"
                                                            : "#E3B100",
                                                      }}
                                                    >
                                                      {status}
                                                    </td>
                                                  </tr>
                                                )
                                              }
                                            )}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MerchantProfile
