import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, } from "reactstrap"

import "../Users/datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import { Link, Redirect } from "react-router-dom"

const OrderedMerchandise = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/merchandise?search=pending`,
          options
        )
        const users = await response.json()
        setUsers(users.data)
        setLoading(false)
        console.log(users.data)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Ordered Merchandise | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="text-success">Ordered Merchandise</h4>
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-success"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <div className="float-end">
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search..."
                          onChange={e => setSearch(e.target.value)}
                        />
                      </div>
                      <Table className="table responsive table-hover table-borderless mt-2 mb-0">
                        <thead>
                          <tr className="mt-5">
                            <th>FIRST NAME</th>
                            <th>LAST NAME</th>
                            <th>EMAIL</th>
                            <th>PHONE</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users
                            ?.filter(val => {
                              if (search === "") {
                                return val
                              } else if (
                                val?.user?.first_name
                                  ?.toLowerCase()
                                  ?.includes(search.toLowerCase())
                              ) {
                                return val
                              } else if (
                                val?.status
                                  ?.toLowerCase()
                                  ?.includes(search?.toLowerCase())
                              ) {
                                return val
                              } else if (
                                val?.user?.email
                                  ?.toLowerCase()
                                  ?.includes(search?.toLowerCase())
                              ) {
                                return val
                              } else if (
                                val?.user?.last_name
                                  ?.toLowerCase()
                                  ?.includes(search?.toLowerCase())
                              ) {
                                return val
                              }
                            })
                            ?.map((merch, index) => {
                              const { id, user, status, user_id } = merch
                              return (
                                <tr
                                  key={id}
                                  onClick={() =>
                                    setRedirect("/merchandise/" + user_id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <td>{user?.first_name}</td>
                                  <td>{user?.last_name}</td>
                                  <td>{user?.email}</td>
                                  <td>{user?.phone}</td>
                                  <td>
                                    <Link
                                      style={{
                                        color:
                                          status === "done"
                                            ? "#00E324"
                                            : status === "cancelled"
                                            ? "#E33700"
                                            : "#E3B100",
                                      }}
                                      to={"/merchandise/" + user_id}
                                    >
                                      {status}
                                    </Link>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OrderedMerchandise
