import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import CurrencyFormat from "react-currency-format"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,

} from "reactstrap"

import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"
import ReactPaginate from "react-paginate"

const AllDebtors = () => {
  const [debtors, setDebtors] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("")
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const getDebtors = async () => {
    const options = {
      method: "GET",
      headers: new Headers({ Authorization: accessToken }),
    }
    const baseURL = process.env.REACT_APP_BASEURL
    const response = await fetch(
      `${baseURL}/api/admin/all-debtors?page=${next}`,
      options
    )
    const debtors = await response.json()
    setDebtors(debtors.data)
    setLoading(false)
    console.log(debtors)
    setLastPage(debtors.meta, "debtors meta")
  }

  useEffect(() => {
    getDebtors()
  }, [next])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Debtors | Cashpally Admin</title>
        </MetaTags>
        <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
          All Debtors
        </h4>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-md-3">
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search..."
                            onChange={e => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>DATE INITIATED</th>
                            <th>DESCRIPTION</th>
                            <th>FIRSTNAME</th>
                            <th>LASTNAME</th>
                            <th>DATE APPROVED</th>
                            <th>AMOUNT</th>
                            <th>DUE DATE</th>
                            <th>OUTSTANDING BALANCE</th>
                            <th>LOAN STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {debtors
                            ?.filter(val => {
                              if (search === "") {
                                return val
                              } else if (
                                val.user.first_name
                                  ?.toLowerCase()
                                  ?.includes(search?.toLocaleLowerCase())
                              ) {
                                return val
                              } else if (
                                val.user.last_name
                                  ?.toLowerCase()
                                  ?.includes(search?.toLocaleLowerCase())
                              ) {
                                return val
                              } else if (
                                val.user.email
                                  ?.toLowerCase()
                                  ?.includes(search?.toLocaleLowerCase())
                              ) {
                                return val
                              } else if (
                                val.user.phone
                                  ?.toLowerCase()
                                  ?.includes(search?.toLocaleLowerCase())
                              ) {
                                return val
                              } else if (
                                val.user.gender
                                  ?.toLowerCase()
                                  ?.includes(search?.toLocaleLowerCase())
                              ) {
                                return val
                              } else if (
                                val.status
                                  ?.toLowerCase()
                                  ?.includes(search?.toLocaleLowerCase())
                              ) {
                                return val
                              }
                            })
                            ?.map(debtor => {
                              const {
                                amount,
                                approval_date,
                                created_at,
                                current_amount,
                                description,
                                due_date,
                                id,
                                user_id,
                                user,
                                status
                              } = debtor
                              return (
                                <tr
                                  key={id}
                                  onClick={() =>
                                    setRedirect("/user/" + user_id)
                                  }
                                  className="table-row"
                                >
                                  <td>
                                    {created_at
                                      ?.toString()
                                      ?.replace(".000000Z", "")
                                      ?.replace("T", " ")}
                                  </td>
                                  <td>{description}</td>
                                  <td>{user?.first_name}</td>
                                  <td>{user?.last_name}</td>
                                  <td>{approval_date}</td>
                                  <CurrencyFormat
                                    value={amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₦"}
                                    renderText={value => <td>{value}</td>}
                                  />
                                  <td>{due_date}</td>
                                  <CurrencyFormat
                                    value={current_amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₦"}
                                    renderText={value => <td>{value}</td>}
                                  />
                                  <td
                                    style={{
                                      color:
                                        status === "disbursed"
                                          ? "#00E324"
                                          : status === "pending"
                                          ? "#E3B100"
                                          : "#E3B100",
                                    }}
                                  >
                                    {status}
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={lastPage?.last_page}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AllDebtors
