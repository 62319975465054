import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { ToastContainer, toast } from "react-toastify"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  Input,
} from "reactstrap"

//Import Breadcrumb
import "../Users/datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import CurrencyFormat from "react-currency-format"
import "./Merchandise.css"
import "react-tabs/style/react-tabs.css"
import { FaSyncAlt, FaArrowLeft } from 'react-icons/fa'
import { Link } from "react-router-dom"

const MerchandiseDetails = props => {
  const [profile, setProfile] = useState([])
  const [loading, setLoading] = useState(true)
  const [loanLimit, setLoanLimit] = useState({})
  const [income, setIncome] = useState({})
  const [buttonLoad, setbuttonLoad] = useState(false)
  const [buttonLoads, setbuttonLoads] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/merchandise?search=${props.match.params.id}`,
          options
        )
        const users = await response.json()
        setProfile(users.data)
        setLoading(false)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            role: "admin",
            user_id: props.match.params.id,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/loan/check-max-loan`,
          options
        )
        const profile = await response.json()
        setLoading(false)
        console.log(profile.data)
        setLoanLimit(profile.data)
        if (response.status === 400) {
          console.log(profile.message)
          setIncome(profile)
          console.log(income)
        }
      } catch (error) {
        console.log("error", error.message)
      }
    }

    fetchData()
  }, [])

  // Approve Merchandise
  const approveMerchandise = async id => {
    console.log(id)
    if (confirm("Are you sure you want to approve this merchandise?")) {
      // do stuff
      setbuttonLoad(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ merchandise_id: id }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/merchandise/complete-merchandise`,
          options
        )
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["data"])
          setbuttonLoad(false)
          setTimeout(function () {
            window.location.reload(1)
          }, 3000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoad(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Approve Merchandise
  const orderMerchandise = async id => {
    console.log(id)
    if (confirm("Are you sure you want to change the status to ordered?")) {
      // do stuff
      setbuttonLoad(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ merchandise_id: id }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/merchandise/order-merchandise`,
          options
        )
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.success(data["data"])
          setbuttonLoad(false)
          setTimeout(function () {
            window.location.reload(1)
          }, 3000)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoad(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Cancel Merchandise
  const cancelMerchandise = async id => {
    console.log(id)
    if (confirm("Are you sure you want to cancel this merchandise?")) {
      // do stuff
      setbuttonLoads(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ merchandise_id: id }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/merchandise/cancel-merchandise`,
          options
        )
        const data = await response.json()
        console.log(data)
        if (data.status == "Success") {
          toast.error(data["data"])
          setTimeout(function () {
            window.location.reload(1)
          }, 3000)
          setbuttonLoads(false)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoads(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Merchandise Details | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <ToastContainer autoClose={3000} />
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="table-responsive">
                  <h3 className="py-2 m-3" style={{ color: "#21CD9A" }}>
                    User Information
                  </h3>
                  <Card>
                    <CardBody>
                      <Link to="./" className="text-success">
                        <FaArrowLeft /> back
                      </Link>
                      <Row className="mt-4">
                        {profile?.slice(0, 1)?.map(detail => {
                          const { user, id } = detail
                          return (
                            <Row key={id}>
                              <Col lg={4} className="display-name">
                                <Row>
                                  <Col lg={12} className="mb-5">
                                    <CardImg
                                      src={user?.selfie_url}
                                      alt={user?.first_name}
                                      className="profile-image"
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <CardImg
                                      src={user?.user_government_id_url}
                                      alt={user?.first_name}
                                      className="profile-image"
                                    />
                                    <a
                                      href={user?.user_government_id_url}
                                      target="_blank"
                                      className="text-white"
                                    >
                                      <Row className="view float-end m-2">
                                        <Col lg={12}>View ID</Col>
                                      </Row>
                                    </a>
                                  </Col>
                                </Row>
                              </Col>

                              <Col lg={6}>
                                <h4 className="user-info my-4">
                                  PERSONAL INFORMATION
                                </h4>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    First name
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.first_name}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Last name
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.last_name}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Email address
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="email"
                                      value={user?.email}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Phone number
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.phone}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Username
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.username}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Date of Birth
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.date_of_birth}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Gender
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.gender}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    User status
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.status}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Loan status
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.loan_status}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Address
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.address}
                                    />
                                  </Col>
                                </Row>

                                <h4 className="user-info my-4">LOAN LIMIT</h4>
                                <Row>
                                  <Col lg={12}>
                                    {loanLimit ? (
                                      <div>
                                        <Row className="mb-4">
                                          <Col lg={4} className="display-name">
                                            Maximum Amount
                                          </Col>
                                          <Col lg={8}>
                                            <CurrencyFormat
                                              value={loanLimit?.max_amount_to_borrow?.toFixed(
                                                2
                                              )}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"₦"}
                                              renderText={value => (
                                                <>
                                                  <Input
                                                    className="input-form mb-3"
                                                    type="text"
                                                    value={value}
                                                  />
                                                </>
                                              )}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="my-4">
                                          <Col lg={4} className="display-name">
                                            Maximum Withdrawal
                                          </Col>
                                          <Col lg={8}>
                                            <CurrencyFormat
                                              value={loanLimit?.max_withdraw_amount?.toFixed(
                                                2
                                              )}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"₦"}
                                              renderText={value => (
                                                <>
                                                  <Input
                                                    className="input-form mb-3"
                                                    type="text"
                                                    value={value}
                                                  />
                                                </>
                                              )}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    ) : (
                                      income.message
                                    )}
                                  </Col>
                                </Row>
                                <h4 className="user-info mt-5 mb-4">
                                  WORK DETAILS
                                </h4>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Organization
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.work_details?.organization}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Job title
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.work_details?.job_title}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Work email address
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.work_details?.email}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4} className="display-name">
                                    Workplace address
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.work_details?.work_address}
                                    />
                                  </Col>
                                </Row>
                                <h4 className="user-info mt-5 mb-4">
                                  USER IDENTIFICATION
                                </h4>
                                <Row>
                                  <Col lg={12} className="mb-4">
                                    <CardImg
                                      src={user?.user_government_id_url}
                                      alt={user?.first_name}
                                      className="profile-image"
                                    />
                                    <a
                                      href={user?.user_identification?.link}
                                      target="_blank"
                                      className="text-white"
                                    ></a>
                                  </Col>
                                  <Col lg={4} className="display-name">
                                    Date Created
                                  </Col>
                                  <Col lg={8}>
                                    <Input
                                      className="input-form mb-3"
                                      type="text"
                                      value={user?.user_identification?.created_at
                                        ?.toString()
                                        ?.replace(".000000Z", "")
                                        ?.replace("T", " ")}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )
                        })}
                      </Row>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <h6 style={{ color: "#21CD9A" }}>MERCHANDISE</h6>
                          <div className="table-responsive">
                            <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                              <thead>
                                <tr style={{ color: "#cccccc" }}>
                                  <th>PRODUCT NAME</th>
                                  <th>MORE DETAILS</th>
                                  <th>QUANTITY</th>
                                  <th>AMOUNT</th>
                                  <th>DELIVERY ADDRESS</th>
                                  <th>CREATED AT</th>
                                  <th>STATUS</th>
                                  <th>VIEW PRODUCT</th>
                                  <th>CHANGE STATUS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {profile?.map(item => {
                                  const {
                                    id,
                                    delivery_address,
                                    more_details,
                                    product_url,
                                    quantity,
                                    amount,
                                    created_at,
                                    status,
                                    product_name,
                                  } = item
                                  return (
                                    <tr key={id}>
                                      {status !== "cancelled" && (
                                        <>
                                          <td>
                                            {product_name
                                              ? product_name
                                              : "No Name"}
                                          </td>
                                          <td>{more_details}</td>
                                          <td>{quantity}</td>
                                          <CurrencyFormat
                                            value={amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"₦"}
                                            renderText={value => (
                                              <td>{value}</td>
                                            )}
                                          />
                                          <td>
                                            {delivery_address
                                              ? delivery_address
                                              : "No Address"}
                                          </td>
                                          <td>
                                            {created_at
                                              ?.replace("T", " ")
                                              .replace(".000000Z", "")}
                                          </td>
                                          <td
                                            style={{
                                              color:
                                                status === "ordered"
                                                  ? "#00E324"
                                                  : status === "cancelled"
                                                  ? "#E33700"
                                                  : status === "done"
                                                  ? "#0af09f"
                                                  : "#E3B100",
                                            }}
                                          >
                                            {status}
                                          </td>
                                          <td>
                                            <a
                                              href={product_url}
                                              target="_blank"
                                              className="badge bg-primary p-2"
                                            >
                                              view product
                                            </a>
                                          </td>
                                        </>
                                      )}
                                      <td>
                                        {status !== "cancelled" ? (
                                          <div className="dropdown">
                                            <button
                                              className="badge border-0 p-2 bg-secondary dropdown-toggle"
                                              type="button"
                                              id="dropdownMenuButton1"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span
                                                style={{ marginRight: "5px" }}
                                              >
                                                Change Status
                                              </span>{" "}
                                              <FaSyncAlt />
                                            </button>
                                            <ul
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton1"
                                            >
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#8ee89c",
                                                }}
                                                onClick={() =>
                                                  orderMerchandise(id)
                                                }
                                              >
                                                Ordered
                                              </li>
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#0af09f",
                                                }}
                                                onClick={() =>
                                                  approveMerchandise(id)
                                                }
                                              >
                                                done
                                              </li>
                                              <li
                                                className="dropdown-item"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#E33700",
                                                }}
                                                onClick={() =>
                                                  cancelMerchandise(id)
                                                }
                                              >
                                                Cancelled
                                              </li>
                                            </ul>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MerchandiseDetails
