import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  Input,
  CardImg,
} from "reactstrap"
import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import CurrencyFormat from "react-currency-format"
import ReactPaginate from "react-paginate"

const CancelledLoans = () => {
  const [cancelledLoans, setCancelledLoans] = useState([])
  const [loading, setLoading] = useState(true)
  const [loan, setLoan] = useState({})
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})

  const handlePageClick = data => {
    console.log("page clicked")
    console.log(data.selected)
    setNext(data.selected + 1)
  }

  const showModal = loan => {
    setLoan(loan)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/loan?query=cancelled&page=${next}`,
          options
        )
        const cancelledLoans = await response.json()
        setCancelledLoans(cancelledLoans.data)
        setLoading(false)
        console.log(cancelledLoans.data)
        setLastPage(cancelledLoans.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [next])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cancelled Loans | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
            Cancelled Loans
          </h4>

          <Row>
            <ToastContainer
              draggable={false}
              transition={Zoom}
              autoClose={3000}
            />
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row mt-3">
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table className="table responsive table-borderless table-striped mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>#</th>
                            <th>FIRST NAME</th>
                            <th>LAST NAME</th>
                            <th>AMOUNT</th>
                            <th>STATUS</th>
                            <th>DESCRIPTION</th>
                            <th>REQUEST DATE</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cancelledLoans?.map((transaction, index) => {
                            const {
                              id,
                              amount,
                              status,
                              description,
                              initial_amount,
                              current_amount,
                              current_duration_in_days,
                              created_at,
                              payment,
                              user,
                            } = transaction
                            return (
                              <tr key={id}>
                                <td>{index + 1}</td>
                                <td>{user?.first_name}</td>
                                <td>{user?.last_name}</td>
                                <CurrencyFormat
                                  value={amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />
                                <td
                                  style={{
                                    color:
                                      status === "paid-off"
                                        ? "#00E324"
                                        : status === "cancelled"
                                        ? "#E33700"
                                        : status === "disbursed"
                                        ? "greenyellow"
                                        : "#E3B100",
                                  }}
                                >
                                  {status}
                                </td>
                                <td>{description}</td>
                                <td>
                                  {created_at
                                    .toString()
                                    ?.replace(".000000Z", "")
                                    ?.replace("T", " ")}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="badge bg-primary border-0 p-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#basicModal"
                                    onClick={() => {
                                      showModal(transaction)
                                    }}
                                  >
                                    View
                                  </button>
                                  <div
                                    class="modal fade"
                                    id="basicModal"
                                    tabindex="-1"
                                  >
                                    <div class="modal-dialog modal-lg">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <h5 class="modal-title">
                                            Loan Details
                                          </h5>
                                          <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                        <div class="modal-body">
                                          <Row>
                                            <h4 className="mb-5">
                                              Loan Information
                                            </h4>
                                            <Col lg={6}>
                                              <strong>Amount: </strong>
                                              <CurrencyFormat
                                                value={loan.amount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                                renderText={value => (
                                                  <span>{value}</span>
                                                )}
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Description: </strong>
                                                {loan.description}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Loan Status: </strong>
                                                {loan.status}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Due Date: </strong>
                                                {loan?.due_date?.replace(
                                                  "00:00:00",
                                                  ""
                                                )}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <strong>Initial Amount: </strong>
                                              <CurrencyFormat
                                                value={loan.initial_amount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                                renderText={value => (
                                                  <p>{value}</p>
                                                )}
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              <strong>
                                                Outstanding Balance:{" "}
                                              </strong>
                                              <CurrencyFormat
                                                value={loan.current_amount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                                renderText={value => (
                                                  <p>{value}</p>
                                                )}
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h4 className="my-5">
                                              User Information
                                            </h4>
                                            <Col lg={6}>
                                              <p>
                                                <strong>First name: </strong>
                                                {!loan.user
                                                  ? "No First name"
                                                  : loan.user.first_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Last name: </strong>
                                                {!loan.user
                                                  ? "No Last name"
                                                  : loan.user.last_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Email: </strong>
                                                {!loan.user
                                                  ? "No email"
                                                  : loan.user.email}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Phone: </strong>
                                                {!loan.user
                                                  ? "No phone"
                                                  : loan.user.phone}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Gender: </strong>
                                                {!loan.user
                                                  ? "Not specified"
                                                  : loan.user.gender}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Loan Status: </strong>
                                                {!loan.user
                                                  ? "null"
                                                  : loan.user.loan_status}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Document
                                            </h5>
                                            <Col md={12} lg={12} xl={12}>
                                              <Card>
                                                <CardImg
                                                  top
                                                  className="img-fluid"
                                                  src={
                                                    !loan.user
                                                      ? "None"
                                                      : loan.user.selfie_url
                                                  }
                                                  alt={
                                                    !loan.user
                                                      ? "No Selfie"
                                                      : loan.user.first_name
                                                  }
                                                />
                                                <CardBody>
                                                  <CardTitle className="h4">
                                                    <a
                                                      href={
                                                        loan?.user?.selfie_url
                                                      }
                                                      target="_blank"
                                                    >
                                                      View Selfie
                                                    </a>
                                                  </CardTitle>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                            <Col md={12} lg={12} xl={12}>
                                              <Card>
                                                <CardImg
                                                  top
                                                  className="img-fluid"
                                                  src={
                                                    !loan.user
                                                      ? "No ID"
                                                      : loan.user
                                                          .user_government_id_url
                                                  }
                                                  alt={
                                                    !loan.user
                                                      ? "No ID"
                                                      : loan.user.first_name
                                                  }
                                                />
                                                <CardBody>
                                                  <CardTitle className="h4">
                                                    <a
                                                      href={
                                                        loan?.user
                                                          ?.user_government_id_url
                                                      }
                                                      target="_blank"
                                                    >
                                                      View ID
                                                    </a>
                                                  </CardTitle>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Payments
                                            </h5>
                                            <Col lg={12}>
                                              <div className="table-responsive">
                                                <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                                                  <thead>
                                                    <tr
                                                      style={{
                                                        color: "#cccccc",
                                                      }}
                                                    >
                                                      <th>#</th>
                                                      <th>FULL NAME</th>
                                                      <th>EMAIL</th>
                                                      <th>PHONE</th>
                                                      <th>AMOUNT</th>
                                                      <th>DATE</th>
                                                      <th>STATUS</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {loan?.payment?.map(
                                                      (initiate, index) => {
                                                        const {
                                                          id,
                                                          amount,
                                                          email,
                                                          full_name,
                                                          phone,
                                                          created_at,
                                                          status,
                                                        } = initiate
                                                        return (
                                                          <tr key={id}>
                                                            <td>{index + 1}</td>
                                                            <td>{full_name}</td>
                                                            <td>{email}</td>
                                                            <td>{phone}</td>
                                                            <td>
                                                              <CurrencyFormat
                                                                value={amount}
                                                                displayType={
                                                                  "text"
                                                                }
                                                                thousandSeparator={
                                                                  true
                                                                }
                                                                prefix={"₦"}
                                                              />
                                                            </td>
                                                            <td>
                                                              {created_at
                                                                ?.replace(
                                                                  "T",
                                                                  " "
                                                                )
                                                                ?.replace(
                                                                  ".000000Z",
                                                                  ""
                                                                )}
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  status ===
                                                                  "successful"
                                                                    ? "#00E324"
                                                                    : status ===
                                                                      "failed"
                                                                    ? "#E33700"
                                                                    : "#E3B100",
                                                              }}
                                                            >
                                                              {status}
                                                            </td>
                                                          </tr>
                                                        )
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div class="modal-footer">
                                          <div className="my-4 text-center">
                                            <button
                                              type="button"
                                              class="btn btn-secondary"
                                              data-bs-dismiss="modal"
                                            >
                                              Close
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={lastPage?.last_page}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CancelledLoans
