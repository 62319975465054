import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  Button,
} from "reactstrap"

import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast, Zoom } from "react-toastify"
import { Redirect } from "react-router-dom"

const AllCompanies = () => {
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [partner, setPartner] = useState({})
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [redirect, setRedirect] = useState("")

  const handlePageClick = data => {
    console.log("page clicked")
    console.log(data.selected)
    setNext(data.selected + 1)
  }

  const showModal = partner => {
    setPartner(partner)
  }

  // update company...
  const updateCompany = async id => {
    if (confirm("Are you sure you want to activate the Merchant?")) {
      // do stuff
      try {
        const options = {
          method: "PUT",
          headers: new Headers({
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ company_id: id }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/company/activate`,
          options
        )
        const data = await response.json()
        // console.log(data)
        // Alert message
        toast.success(data.message)
        if (data.status == "Success") {
          //reload page
          setTimeout(function () {
            window.location.reload(1)
          }, 3000)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/company/all?page=${next}`,
          options
        )
        const companies = await response.json()
        setCompanies(companies.data)
        setLoading(false)
        console.log(companies)
        setLastPage(companies.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [next])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Companies | Cashpally Admin</title>
        </MetaTags>
        <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
          All Merchants
        </h4>
        <div className="container-fluid">
          <Row>
            <ToastContainer
              draggable={false}
              transition={Zoom}
              autoClose={3000}
            />
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row mt-3">
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>#</th>
                            <th>COMPANY NAME</th>
                            <th>EMAIL</th>
                            <th>PHONE</th>
                            <th>CITY</th>
                            <th>STATE</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {companies?.map((company, index) => {
                            const {
                              id,
                              city,
                              company_name,
                              company_address,
                              phone,
                              company_email,
                              state,
                              status,
                              contact,
                              user_details,
                            } = company
                            return (
                              <tr
                                key={id}
                                // onClick={() => setRedirect("/merchant/" + user_details.id)}
                                // className="table-row"
                              >
                                <td>{index + 1}</td>
                                <td>{company_name}</td>
                                <td>{company_email}</td>
                                <td>{phone}</td>
                                <td>{city}</td>
                                <td>{state}</td>
                                <td
                                  style={{
                                    color:
                                      status === "activated"
                                        ? "#00E324"
                                        : status === "suspended"
                                        ? "#E33700"
                                        : "#E3B100",
                                  }}
                                >
                                  {status}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="badge bg-primary border-0 p-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#basicModal"
                                    onClick={() => {
                                      showModal(company)
                                    }}
                                  >
                                    View
                                  </button>
                                  <div
                                    className="modal fade"
                                    id="basicModal"
                                    tabIndex="-1"
                                  >
                                    <div className="modal-dialog modal-lg">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5 className="modal-title">
                                            Merchant Details
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                        <div className="modal-body">
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Company Information
                                            </h5>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Company name: </strong>
                                                {partner?.company_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Email: </strong>
                                                {partner?.company_email}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Address: </strong>
                                                {partner?.company_address}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Phone: </strong>
                                                {partner?.phone}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>State: </strong>
                                                {partner?.state}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Services
                                            </h5>
                                            <Col lg={6}>
                                              {partner?.services?.map(
                                                (service, index) => {
                                                  return (
                                                    <div key={index}>
                                                      <p>
                                                        {service?.services[0]}
                                                      </p>
                                                      <p>
                                                        {service?.services[1]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[2]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[3]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[4]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[5]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[6]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[7]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[8]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[9]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[10]}
                                                      </p>
                                                      <p key={service?.id}>
                                                        {service?.services[11]}
                                                      </p>
                                                    </div>
                                                  )
                                                }
                                              )}
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Contact Information
                                            </h5>
                                            <Col lg={6}>
                                              <p>
                                                <strong>First name: </strong>
                                                {!partner?.contact
                                                  ? "null"
                                                  : partner?.contact
                                                      ?.title}{" "}
                                                {!partner?.contact
                                                  ? "null"
                                                  : partner?.contact
                                                      ?.first_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Last name: </strong>
                                                {!partner?.contact
                                                  ? "null"
                                                  : partner?.contact?.last_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Address: </strong>
                                                {!partner?.contact
                                                  ? "null"
                                                  : partner?.contact?.address}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Phone: </strong>
                                                {!partner?.contact
                                                  ? "null"
                                                  : partner?.contact?.phone}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Gender: </strong>
                                                {!partner?.contact
                                                  ? "null"
                                                  : partner?.contact?.gender}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>DOB: </strong>
                                                {!partner?.contact
                                                  ? "null"
                                                  : partner?.contact?.DOB}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>City: </strong>
                                                {!partner?.contact
                                                  ? "null"
                                                  : partner?.contact?.city}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>State: </strong>
                                                {!partner?.contact
                                                  ? "null"
                                                  : partner?.contact?.state}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Document
                                            </h5>
                                            <Col md={12} lg={12} xl={12}>
                                              <Card>
                                                <a
                                                  href={partner?.document?.CAC}
                                                  target="_blank"
                                                >
                                                  <CardImg
                                                    top
                                                    className="img-fluid"
                                                    src={
                                                      !partner?.document
                                                        ? ""
                                                        : partner?.document?.CAC
                                                    }
                                                    alt={
                                                      !partner?.document
                                                        ? ""
                                                        : partner?.document?.CAC
                                                    }
                                                  />
                                                </a>
                                                <CardBody>
                                                  <CardTitle className="h4">
                                                    {partner?.document?.CAC ? (
                                                      <a
                                                        className="btn btn-primary"
                                                        href={
                                                          !partner?.document
                                                            ? ""
                                                            : partner?.document
                                                                ?.CAC
                                                        }
                                                        target="_blank"
                                                      >
                                                        View CAC
                                                      </a>
                                                    ) : (
                                                      "No CAC"
                                                    )}
                                                  </CardTitle>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                            <Col md={12} lg={12} xl={12}>
                                              <Card>
                                                <a
                                                  href={
                                                    partner?.document?.id_card
                                                  }
                                                  target="_blank"
                                                >
                                                  <CardImg
                                                    top
                                                    className="img-fluid"
                                                    src={
                                                      !partner?.document
                                                        ? ""
                                                        : partner?.document
                                                            ?.id_card
                                                    }
                                                    alt={
                                                      !partner?.document
                                                        ? ""
                                                        : partner?.document
                                                            ?.id_card
                                                    }
                                                  />
                                                </a>
                                                <CardBody>
                                                  <CardTitle className="h4">
                                                    {partner?.document
                                                      ?.id_card ? (
                                                      <a
                                                        className="btn btn-primary"
                                                        href={
                                                          !partner?.document
                                                            ? ""
                                                            : partner?.document
                                                                ?.id_card
                                                        }
                                                        target="_blank"
                                                      >
                                                        View ID card
                                                      </a>
                                                    ) : (
                                                      "No ID"
                                                    )}
                                                  </CardTitle>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                          >
                                            Close
                                          </button>
                                          {partner?.status === "pending" && (
                                            <Button
                                              className="btn btn-success"
                                              onClick={() =>
                                                updateCompany(partner.id)
                                              }
                                            >
                                              Activate
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={lastPage?.last_page}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AllCompanies
