import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"
import ReactPaginate from "react-paginate"

const ActiveUsers = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("")
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})

    const handlePageClick = data => {
      console.log(data.selected)
      setNext(data.selected + 1)
    }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/user?search=${search}&page=${next}`,
          options
        )
        const users = await response.json()
        setUsers(users.data)
        setLoading(false)
        console.log(users)
        setLastPage(users.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [search, next])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Active users | Cashpally Admin</title>
        </MetaTags>
        <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
          Active Users
        </h4>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-md-3">
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search..."
                            value={search}
                            onChange={e => {
                              setSearch(e.target.value)
                              console.log(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>FIRST NAME</th>
                            <th>LAST NAME</th>
                            <th>USERNAME</th>
                            <th>EMAIL ADDRESS</th>
                            <th>PHONE NUMBER</th>
                            <th>REGISTERED</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map(user => {
                            const {
                              id,
                              first_name,
                              last_name,
                              email,
                              phone,
                              status,
                              username,
                              created_at,
                            } = user
                            return status === "active" ? (
                              <tr
                                key={id}
                                onClick={() => setRedirect("/user/" + id)}
                                className="table-row"
                              >
                                <td>{first_name ? first_name : "Null"}</td>
                                <td>{last_name ? last_name : "Null"}</td>
                                <td>{username ? username : "No username"}</td>
                                <td>{email ? email : "No email"}</td>
                                <td>{phone}</td>
                                <td>
                                  {created_at
                                    ?.replace("T", " ")
                                    ?.replace(".000000Z", "")}
                                </td>
                                <td
                                  style={{
                                    color:
                                      status === "active"
                                        ? "#00E324"
                                        : status === "inactive"
                                        ? "#E33700"
                                        : "#E3B100",
                                  }}
                                >
                                  {status}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )
                          })}
                        </tbody>
                      </Table>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={lastPage?.last_page}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ActiveUsers
