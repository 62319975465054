import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import CurrencyFormat from "react-currency-format"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"
import ReactPaginate from "react-paginate"

const AllLoans = () => {
  const [allLoans, setAllLoans] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})

  const handlePageClick = data => {
    console.log("page clicked")
    console.log(data.selected)
    setNext(data.selected + 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/loan?page=${next}`,
          options
        )
        const allLoans = await response.json()
        setAllLoans(allLoans.data)
        setLoading(false)
        console.log(allLoans)
        setLastPage(allLoans.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [next])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Loans | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
            All Loans
          </h4>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row mt-3">
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>#</th>
                            <th>First name</th>
                            <th>Last Name</th>
                            <th>Amount</th>
                            <th>Loan Status</th>
                            <th>Description</th>
                            <th>Request Date</th>
                            <th>Approval Date</th>
                            <th>Due Date</th>
                            <th>Initial Amount</th>
                            <th>Outstanding Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allLoans.map((transaction, index) => {
                            const {
                              id,
                              amount,
                              created_at,
                              status,
                              description,
                              approval_date,
                              extension_status,
                              due_date,
                              initial_amount,
                              current_amount,
                              payment,
                              user_id,
                              user,
                            } = transaction
                            return (
                              <tr
                                key={id}
                                onClick={() => setRedirect("/user/" + user_id)}
                                className="table-row"
                              >
                                <td>{index + 1}</td>
                                <td>{user?.first_name}</td>
                                <td>{user?.last_name}</td>
                                <CurrencyFormat
                                  value={amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />
                                <td
                                  style={{
                                    color:
                                      status === "paid-off"
                                        ? "#00E324"
                                        : status === "pending"
                                        ? "#E3B100"
                                        : status === "disbursed"
                                        ? "#77f2a4"
                                        : "#E33700",
                                  }}
                                >
                                  {status}
                                </td>
                                <td>{description}</td>
                                <td>
                                  {created_at
                                    ?.replace("T", " ")
                                    ?.replace(".000000Z", "")}
                                </td>
                                <td>
                                  {approval_date
                                    ? approval_date
                                    : "Not yet approved"}
                                </td>
                                <td>{due_date}</td>
                                <CurrencyFormat
                                  value={initial_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />
                                <CurrencyFormat
                                  value={current_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={lastPage?.last_page}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AllLoans
