import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../Users/datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import CurrencyFormat from "react-currency-format"
import { Redirect } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"

const WeeklyFeeDefaulters = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [defaulter, setDefaulter] = useState({})
  const [search, setSearch] = useState("")

  const showModal = defaulter => {
    setDefaulter(defaulter)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/weekly-fee-defaulters`,
          options
        )
        const users = await response.json()
        setUsers(users.data)
        setLoading(false)
        console.log(users.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // delete failed charge
  const deleteFailedCharge = async id => {
    // do stuff
    console.log(id)
    try {
      const options = {
        method: "DELETE",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          id,
        }),
      }
      const baseURL = process.env.REACT_APP_BASEURL
      const response = await fetch(
        `${baseURL}/api/admin/loan/weekly-fee/failed-charge`,
        options
      )
      const data = await response.json()
      console.log(data.errors.loan_id)
      toast(data.message)
      if (data.status == "Success") {
        toast.success(data["message"])
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status == "Error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Weekly Fee Defaulters | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
                    Weekly Fee Defaulters
                  </h4>
                  {loading ? (
                    <div className="container">
                      <div className="row mt-4">
                        <div className="col-md-3 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      {users?.length !== 0 ? (
                        <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                          <thead>
                            <tr className="mt-5" style={{ color: "#cccccc" }}>
                              <th>FULL NAME</th>
                              <th>OUTSTANDING</th>
                              <th>INITIAL AMOUNT</th>
                              <th>WEEKLY CHARGE</th>
                              <th>CHARGE DATE</th>
                              <th>APPROVAL DATE</th>
                              <th>DUE DATE</th>
                              <th>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users
                              ?.filter(val => {
                                if (search === "") {
                                  return val
                                } else if (
                                  val?.user?.first_name
                                    ?.toLowerCase()
                                    ?.includes(search.toLowerCase())
                                ) {
                                  return val
                                } else if (
                                  val?.status
                                    ?.toLowerCase()
                                    ?.includes(search?.toLowerCase())
                                ) {
                                  return val
                                } else if (
                                  val?.user?.last_name
                                    ?.toLowerCase()
                                    ?.includes(search?.toLowerCase())
                                ) {
                                  return val
                                }
                              })
                              ?.map((merch, index) => {
                                const {
                                  id,
                                  borrower_name,
                                  user,
                                  status,
                                  description,
                                  initial_amount,
                                  current_amount,
                                  approval_date,
                                  due_date,
                                  user_id,
                                  weekly_charge,
                                  weekly_charge_date,
                                } = merch
                                return (
                                  <tr key={id}>
                                    <td>{borrower_name}</td>
                                    <CurrencyFormat
                                      value={current_amount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      renderText={value => <td>{value}</td>}
                                    />
                                    <CurrencyFormat
                                      value={initial_amount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      renderText={value => <td>{value}</td>}
                                    />

                                    <CurrencyFormat
                                      value={weekly_charge}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      renderText={value => <td>{value}</td>}
                                    />
                                    <td>
                                      {weekly_charge_date?.replace(
                                        "00:00:00",
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {approval_date
                                        ?.replace("T", " ")
                                        ?.replace(".000000Z", "")}
                                    </td>
                                    <td>
                                      {due_date
                                        ?.replace("T", " ")
                                        ?.replace(".000000Z", "")
                                        ?.replace("00:00:00", "")}
                                    </td>
                                    {/* <td>
                                      <button
                                        type="button"
                                        className="badge bg-danger border-0 p-2"
                                        onClick={() => {
                                          deleteFailedCharge(id)
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </td> */}
                                    <td>
                                      <button
                                        type="button"
                                        className="badge bg-primary border-0 p-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#basicModal"
                                        onClick={() => {
                                          showModal(merch)
                                        }}
                                      >
                                        View
                                      </button>
                                      <div
                                        className="modal fade"
                                        id="basicModal"
                                        tabIndex="-1"
                                      >
                                        <div className="modal-dialog modal-xl">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                style={{ color: "#21CD9A" }}
                                              >
                                                Defaulter Details
                                              </h5>
                                              <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <div className="container">
                                                <div className="row mb-3">
                                                  <h5
                                                    style={{ color: "#21CD9A" }}
                                                  >
                                                    User Details
                                                  </h5>
                                                  <div className="col-md-12 mb-3">
                                                    <img
                                                      src={
                                                        defaulter?.user
                                                          ?.selfie_url
                                                      }
                                                      style={{
                                                        maxWidth: "300px",
                                                        maxHeight: "300px",
                                                      }}
                                                      className="img-fluid rounded"
                                                      alt="selfie"
                                                    />
                                                  </div>
                                                  <div className="col-md-4">
                                                    <h5
                                                      style={{
                                                        color: "#21CD9A",
                                                      }}
                                                    >
                                                      {defaulter?.borrower_name}
                                                    </h5>
                                                    <span className="text-secondary">
                                                      {defaulter?.user?.email}
                                                    </span>{" "}
                                                    <br />
                                                    <span className="text-secondary">
                                                      {defaulter?.user?.phone}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="container">
                                                <div className="row mb-3">
                                                  <h5
                                                    style={{ color: "#21CD9A" }}
                                                  >
                                                    Failed Weekly Charge
                                                  </h5>
                                                  <div className="col-md-4">
                                                    <span className="text-secondary">
                                                      Attempt
                                                    </span>
                                                    <h6>
                                                      {
                                                        defaulter
                                                          ?.failed_weekly_charge
                                                          ?.attempt
                                                      }
                                                    </h6>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <span className="text-secondary">
                                                      Reference
                                                    </span>
                                                    <h6>
                                                      {
                                                        defaulter
                                                          ?.failed_weekly_charge
                                                          ?.reference
                                                      }
                                                    </h6>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <span className="text-secondary">
                                                      Week Due
                                                    </span>
                                                    <h6>
                                                      {
                                                        defaulter
                                                          ?.failed_weekly_charge
                                                          ?.week_due
                                                      }
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="container">
                                                <div className="row my-3">
                                                  <h5
                                                    style={{ color: "#21CD9A" }}
                                                  >
                                                    Payments
                                                  </h5>
                                                  <div className="col-md-12">
                                                    <div className="table-responsive">
                                                      <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                                                        <thead>
                                                          <tr
                                                            style={{
                                                              color: "#cccccc",
                                                            }}
                                                          >
                                                            <th>#</th>
                                                            <th>FULL NAME</th>
                                                            <th>EMAIL</th>
                                                            <th>PHONE</th>
                                                            <th>AMOUNT</th>
                                                            <th>DATE</th>
                                                            <th>STATUS</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {defaulter?.payment?.map(
                                                            (
                                                              initiate,
                                                              index
                                                            ) => {
                                                              const {
                                                                id,
                                                                amount,
                                                                email,
                                                                full_name,
                                                                phone,
                                                                created_at,
                                                                status,
                                                              } = initiate
                                                              return (
                                                                <tr key={id}>
                                                                  <td>
                                                                    {index + 1}
                                                                  </td>
                                                                  <td>
                                                                    {full_name}
                                                                  </td>
                                                                  <td>
                                                                    {email}
                                                                  </td>
                                                                  <td>
                                                                    {phone}
                                                                  </td>
                                                                  <td>
                                                                    <CurrencyFormat
                                                                      value={
                                                                        amount
                                                                      }
                                                                      displayType={
                                                                        "text"
                                                                      }
                                                                      thousandSeparator={
                                                                        true
                                                                      }
                                                                      prefix={
                                                                        "₦"
                                                                      }
                                                                    />
                                                                  </td>
                                                                  <td>
                                                                    {created_at
                                                                      ?.replace(
                                                                        "T",
                                                                        " "
                                                                      )
                                                                      ?.replace(
                                                                        ".000000Z",
                                                                        ""
                                                                      )}
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      color:
                                                                        status ===
                                                                        "successful"
                                                                          ? "#00E324"
                                                                          : status ===
                                                                            "failed"
                                                                          ? "#E33700"
                                                                          : "#E3B100",
                                                                    }}
                                                                  >
                                                                    {status}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            }
                                                          )}
                                                        </tbody>
                                                      </Table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                              >
                                                Close
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-5">
                          <h4 style={{ color: "#21CD9A" }}>
                            Failed to fetch
                          </h4>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default WeeklyFeeDefaulters
