import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, } from "reactstrap"
import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"
import ReactPaginate from "react-paginate"
import CurrencyFormat from "react-currency-format"

const UsersSpending = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("10000")
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/spending-limit/${search}?page=${next}`,
          options
        )
        const users = await response.json()
        setUsers(Object.values(users.data))
        setLoading(false)
        setLastPage(users.total / users.per_page)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [search, next])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users With Spending Limit | Cashpally Admin</title>
        </MetaTags>
        <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
          Users With Spending Limit
        </h4>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-md-3">
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Filter..."
                            value={search}
                            onChange={e => {
                              setSearch(e.target.value)
                            }}
                          />
                        </div>
                        <div className="container">
                          <div className="row">
                            <h4
                              className="d-flex justify-content-center align-items-center my-4"
                              style={{ color: "#21CD9A" }}
                            >
                              <CurrencyFormat
                                value={search}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₦ "}
                                renderText={value => <>{value}</>}
                              />
                            </h4>
                          </div>
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-md-6">
                              <label htmlFor="customRange2" className="form-label">
                                Filter Amount
                              </label>
                              <input
                                type="range"
                                className="form-range"
                                min="10000"
                                max="100000"
                                step="10000"
                                id="customRange2"
                                value={search}
                                onChange={e => {
                                  setLoading(true)
                                  setSearch(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>FIRST NAME</th>
                            <th>LAST NAME</th>
                            <th>EMAIL ADDRESS</th>
                            <th>PHONE NUMBER</th>
                            <th>SPENDING LIMIT</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map(user => {
                            const {
                              id,
                              first_name,
                              last_name,
                              email,
                              phone,
                              status,
                              username,
                              created_at,
                              user_income,
                            } = user
                            return (
                              user_income?.spending_limit && (
                                <tr
                                  key={id}
                                  onClick={() => setRedirect("/user/" + id)}
                                  className="table-row"
                                >
                                  <td>{first_name ? first_name : "Null"}</td>
                                  <td>{last_name ? last_name : "Null"}</td>
                                  <td>{email ? email : "No email"}</td>
                                  <td>{phone}</td>
                                  <CurrencyFormat
                                    value={user_income?.spending_limit}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"₦ "}
                                    renderText={value => <td>{value}</td>}
                                  />
                                  <td
                                    style={{
                                      color:
                                        status === "active"
                                          ? "#00E324"
                                          : status === "inactive"
                                          ? "#E33700"
                                          : "#E3B100",
                                    }}
                                  >
                                    {status}
                                  </td>
                                </tr>
                              )
                            )
                          })}
                        </tbody>
                      </Table>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={Math.ceil(lastPage)}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UsersSpending
