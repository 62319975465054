import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import "./Users.css"

import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"
import "./UserProfile.css"

import ReactPaginate from "react-paginate"
import CurrencyFormat from "react-currency-format"

const Defaulters = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("")
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/all-defaulters?search=${search}&page=${next}`,
          options
        )
        const users = await response.json()
        setUsers(users.data)
        setLoading(false)
        console.log(users)
        setLastPage(users.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [next, search])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Defaulters | Cashpally Admin</title>
        </MetaTags>
        <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
          Defaulters
        </h4>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>FIRST NAME</th>
                            <th>LAST NAME</th>
                            <th>EMAIL ADDRESS</th>
                            <th>PHONE NUMBER</th>
                            <th>AMOUNT</th>
                            <th>OUTSTANDING</th>
                            <th>DUE DATE</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map((defaulter, index) => {
                            const { id, user_id, status, amount, current_amount, user, due_date, } =
                              defaulter
                            return (
                              <tr
                                key={id}
                                onClick={() => setRedirect("/user/" + user_id)}
                                className="table-row"
                              >
                                <td>{user?.first_name}</td>
                                <td>{user?.last_name}</td>
                                <td>{user?.email}</td>
                                <td>{user?.phone}</td>
                                <CurrencyFormat
                                  value={amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />
                                <CurrencyFormat
                                  value={current_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />
                                <td>{due_date.replace("00:00:00", "")}</td>
                                <td
                                  style={{
                                    color:
                                      status === "disbursed"
                                        ? "#00E324"
                                        : status === "pending"
                                        ? "#E3B100"
                                        : status === "suspended" || "defaulter"
                                        ? "#E33700"
                                        : "#E3B100",
                                  }}
                                >
                                  {status}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={lastPage?.last_page}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Defaulters
