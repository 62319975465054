import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  CardImg,
} from "reactstrap"

import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import CurrencyFormat from "react-currency-format"
import Modal from "react-modal"
import ReactPaginate from "react-paginate"

const PendingLoans = () => {
  const [pendingLoans, setPendingLoans] = useState([])
  const [loading, setLoading] = useState(true)
  const [loan, setLoan] = useState({})
  const [reason, setReason] = useState("")
  const [buttonLoad, setbuttonLoad] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const showModal = loan => {
    setLoan(loan)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/loan?query=pending&page=${next}`,
          options
        )
        const pendingLoans = await response.json()
        setPendingLoans(pendingLoans.data)
        setLoading(false)
        setLastPage(pendingLoans.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchData()
  }, [next])

  // approve loan

  const approveLoan = async id => {
    if (confirm("Are you sure you want to approve this loan?")) {
      // do stuff
      try {
        const options = {
          method: "PUT",
          headers: new Headers({
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ loan_id: id }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/loan/approve`,
          options
        )
        const data = await response.json()
        // Alert message
        toast.success(data.message)
        if (data.status == "Success") {
          //reload page
          setTimeout(function () {
            window.location.reload(1)
          }, 3200)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // decline loan

  const declineLoan = async (id, mreason) => {
    // do stuff
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          loan_id: id,
          reason: mreason,
        }),
      }
      const baseURL = process.env.REACT_APP_BASEURL
      const response = await fetch(`${baseURL}/api/admin/loan/decline`, options)
      const data = await response.json()
      toast.success(data.message)
      setTimeout(function () {
        window.location.reload(1)
      }, 3200)
    } catch (error) {
      // do what
      console.log(error)
    }
  }

  // Initiate Payment
  const initiatePayment = async id => {
    if (confirm("Are you sure you want to confirm this payment?")) {
      // do stuff
      setbuttonLoad(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({ loan_id: id }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/loan/first-payment`,
          options
        )
        const data = await response.json()
        if (data.status == "Success") {
          toast.success(data["data"])
          setbuttonLoad(false)
        } else if (data.status == "Error") {
          toast.error(data["message"])
          setbuttonLoad(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pending Loans | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
            Pending Loans
          </h4>

          <Row>
            <ToastContainer
              draggable={false}
              transition={Zoom}
              autoClose={3000}
            />
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row mt-3">
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table className="table responsive table-borderless table-striped mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>#</th>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>Status</th>
                            <th>Description</th>
                            <th>Initial Amount</th>
                            <th>Outstanding balance</th>
                            <th>Request Date</th>
                            <th>Due Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pendingLoans.map((transaction, index) => {
                            const {
                              id,
                              amount,
                              status,
                              description,
                              initial_amount,
                              current_amount,
                              created_at,
                              payment,
                              due_date,
                              user,
                            } = transaction
                            return (
                              <tr key={id}>
                                <td>{index + 1}</td>
                                <td>{user?.first_name}</td>
                                <td>{user?.last_name}</td>
                                <td
                                  style={{
                                    color:
                                      status === "paid-off"
                                        ? "#00E324"
                                        : status === "cancelled"
                                        ? "#E33700"
                                        : status === "disbursed"
                                        ? "greenyellow"
                                        : "#E3B100",
                                  }}
                                >
                                  {status}
                                </td>
                                <td>{description}</td>
                                <CurrencyFormat
                                  value={initial_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />
                                <CurrencyFormat
                                  value={current_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />
                                <td>
                                  {created_at
                                    ?.toString()
                                    ?.replace(".000000Z", "")
                                    ?.replace("T", " ")}
                                </td>
                                <td>{due_date?.replace("00:00:00", "")}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="badge bg-primary border-0 p-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#basicModal"
                                    onClick={() => {
                                      showModal(transaction)
                                    }}
                                  >
                                    View
                                  </button>
                                  <div
                                    className="modal fade"
                                    id="basicModal"
                                    tabIndex="-1"
                                  >
                                    <div className="modal-dialog modal-xl">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5 className="modal-title">
                                            Loan Details
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                        <div className="modal-body">
                                          <Row>
                                            <h4 className="mb-5">
                                              Loan Information
                                            </h4>
                                            <Col lg={6}>
                                              <strong>Amount: </strong>
                                              <CurrencyFormat
                                                value={loan.amount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                                renderText={value => (
                                                  <span>{value}</span>
                                                )}
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Description: </strong>
                                                {loan.description}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Loan Status: </strong>
                                                {loan.status}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Due Date: </strong>
                                                {loan?.due_date?.replace(
                                                  "00:00:00",
                                                  ""
                                                )}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <strong>Initial Amount: </strong>
                                              <CurrencyFormat
                                                value={loan.initial_amount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                                renderText={value => (
                                                  <p>{value}</p>
                                                )}
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              <strong>
                                                Outstanding Balance:{" "}
                                              </strong>
                                              <CurrencyFormat
                                                value={loan.current_amount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                                renderText={value => (
                                                  <p>{value}</p>
                                                )}
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h4 className="my-5">
                                              User Information
                                            </h4>
                                            <Col lg={6}>
                                              <p>
                                                <strong>First name: </strong>
                                                {!loan.user
                                                  ? "No First name"
                                                  : loan.user.first_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Last name: </strong>
                                                {!loan.user
                                                  ? "No Last name"
                                                  : loan.user.last_name}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Email: </strong>
                                                {!loan.user
                                                  ? "No email"
                                                  : loan.user.email}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Phone: </strong>
                                                {!loan.user
                                                  ? "No phone"
                                                  : loan.user.phone}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Gender: </strong>
                                                {!loan.user
                                                  ? "Not specified"
                                                  : loan.user.gender}
                                              </p>
                                            </Col>
                                            <Col lg={6}>
                                              <p>
                                                <strong>Loan Status: </strong>
                                                {!loan.user
                                                  ? "null"
                                                  : loan.user.loan_status}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Document
                                            </h5>
                                            <Col md={12} lg={12} xl={12}>
                                              <Card>
                                                <CardImg
                                                  top
                                                  className="img-fluid text-center"
                                                  src={
                                                    !loan.user
                                                      ? "None"
                                                      : loan.user.selfie_url
                                                  }
                                                  alt={
                                                    !loan.user
                                                      ? "No Selfie"
                                                      : loan.user.first_name
                                                  }
                                                  style={{ maxWidth: "500px" }}
                                                />
                                                <CardBody>
                                                  <CardTitle className="h4">
                                                    <a
                                                      href={
                                                        loan?.user?.selfie_url
                                                      }
                                                      target="_blank"
                                                    >
                                                      View Selfie
                                                    </a>
                                                  </CardTitle>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                            <Col md={12} lg={12} xl={12}>
                                              <Card>
                                                <CardImg
                                                  top
                                                  className="img-fluid"
                                                  src={
                                                    !loan.user
                                                      ? "No ID"
                                                      : loan.user
                                                          .user_government_id_url
                                                  }
                                                  alt={
                                                    !loan.user
                                                      ? "No ID"
                                                      : loan.user.first_name
                                                  }
                                                  style={{ maxWidth: "800px" }}
                                                />
                                                <CardBody>
                                                  <CardTitle className="h4">
                                                    <a
                                                      href={
                                                        loan?.user
                                                          ?.user_government_id_url
                                                      }
                                                      target="_blank"
                                                    >
                                                      View ID
                                                    </a>
                                                  </CardTitle>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <h5 className="mb-4 text-success">
                                              Payments
                                            </h5>
                                            <Col lg={12}>
                                              <div className="table-responsive">
                                                <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                                                  <thead>
                                                    <tr
                                                      style={{
                                                        color: "#cccccc",
                                                      }}
                                                    >
                                                      <th>#</th>
                                                      <th>FULL NAME</th>
                                                      <th>EMAIL</th>
                                                      <th>PHONE</th>
                                                      <th>AMOUNT</th>
                                                      <th>DATE</th>
                                                      <th>STATUS</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {loan?.payment?.map(
                                                      (initiate, index) => {
                                                        const {
                                                          id,
                                                          amount,
                                                          email,
                                                          full_name,
                                                          phone,
                                                          created_at,
                                                          status,
                                                        } = initiate
                                                        return (
                                                          <tr key={id}>
                                                            <td>{index + 1}</td>
                                                            <td>{full_name}</td>
                                                            <td>{email}</td>
                                                            <td>{phone}</td>
                                                            <td>
                                                              <CurrencyFormat
                                                                value={amount}
                                                                displayType={
                                                                  "text"
                                                                }
                                                                thousandSeparator={
                                                                  true
                                                                }
                                                                prefix={"₦"}
                                                              />
                                                            </td>
                                                            <td>
                                                              {created_at
                                                                ?.replace(
                                                                  "T",
                                                                  " "
                                                                )
                                                                ?.replace(
                                                                  ".000000Z",
                                                                  ""
                                                                )}
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  status ===
                                                                  "successful"
                                                                    ? "#00E324"
                                                                    : status ===
                                                                      "failed"
                                                                    ? "#E33700"
                                                                    : "#E3B100",
                                                              }}
                                                            >
                                                              {status}
                                                            </td>
                                                          </tr>
                                                        )
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="modal-footer">
                                          <div className="my-4 text-center">
                                            <button
                                              onClick={() =>
                                                setModalIsOpen(true)
                                              }
                                              type="button"
                                              className="btn btn-danger waves-effect waves-light"
                                              data-toggle="modal"
                                              data-target=".bs-example-modal-sm"
                                              data-bs-dismiss="modal"
                                            >
                                              Decline
                                            </button>
                                          </div>
                                          <Modal
                                            size="md"
                                            isOpen={modalIsOpen}
                                            onRequestClose={() =>
                                              setModalIsOpen(false)
                                            }
                                            style={{
                                              overlay: {
                                                background:
                                                  "rgba(0, 0, 0, 0.05)",
                                              },
                                              content: {
                                                color: "#21CD9A",
                                                top: "50%",
                                                left: "50%",
                                                right: "auto",
                                                bottom: "auto",
                                                marginRight: "-50%",
                                                transform:
                                                  "translate(-50%, -50%)",
                                                width: "500px",
                                              },
                                            }}
                                          >
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title mt-0"
                                                id="mySmallModalLabel"
                                              >
                                                Decline Loan
                                              </h5>
                                              <button
                                                onClick={() =>
                                                  setModalIsOpen(false)
                                                }
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span aria-hidden="true">
                                                  &times;
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body">
                                              What are the reasons for declining
                                              this loan
                                              <Input
                                                className="my-3"
                                                type="textarea"
                                                value={reason}
                                                onChange={e =>
                                                  setReason(e.target.value)
                                                }
                                                style={{ height: "120px" }}
                                              />
                                              <Button
                                                className="btn btn-success"
                                                onClick={() => {
                                                  declineLoan(loan.id, reason)
                                                  setModalIsOpen(false)
                                                }}
                                              >
                                                Ok
                                              </Button>
                                            </div>
                                          </Modal>

                                          <Button
                                            className="btn btn-success"
                                            onClick={() => approveLoan(loan.id)}
                                          >
                                            Disburse
                                          </Button>
                                          <button
                                            onClick={() => {
                                              initiatePayment(loan.id)
                                            }}
                                            className="btn btn-primary waves-effect waves-light"
                                            disabled={buttonLoad}
                                          >
                                            Initiate Payment
                                            {buttonLoad && (
                                              <div
                                                className="spinner-border text-light mx-3"
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                }}
                                                role="status"
                                              >
                                                <span className="visually-hidden">
                                                  Loading...
                                                </span>
                                              </div>
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={lastPage?.last_page}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PendingLoans
