import axios from "axios"
import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Link, Redirect } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"

// import images
import logoSm from "../../assets/images/cashpally-logo-sm.png"


const url = (window.location.origin)

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [role, setRole] = useState("admin")
  const [redirect, setRedirect] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    setRole("admin")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ email, password, role }),
    }
    const baseURL = process.env.REACT_APP_BASEURL
    const response = fetch(`${baseURL}/api/auth/login`, options)
      .then(data => data.json())
      .then(response => {
        toast(response.message)
        console.log(response.data)
        localStorage.setItem("authUser", response.data.token)
        window.location = `${url}/dashboard`
      })
      .catch(error => {
        console.log(error)
        toast(error.message)
      })
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Cashpally Admin Dashboard</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <ToastContainer autoClose={3000} />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white-50">
                      Sign in to continue to Cashpally Admin.
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Label className="mt-5">Email</Label>
                    <Input
                      type="text"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Enter Email"
                    />

                    <Label className="mt-3">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      placeholder="Enter Password"
                    />

                    <Row className="my-3">
                      <Col sm={6}></Col>
                      <Col sm={6} className="text-end">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login
