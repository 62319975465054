import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, CardTitle, Modal } from "reactstrap"

import "./datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import CurrencyFormat from "react-currency-format"
import { Redirect } from "react-router-dom"

const DebitTransactions = () => {
  const [debitTransactions, setDebitTransactions] = useState([])
  const [loading, setLoading] = useState(true)
  const [modal_standard, setmodal_standard] = useState(false)
  const [transact, setTransact] = useState({})
  const [redirect, setRedirect] = useState("")

  function tog_standard(transact) {
    setTransact(transact)
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const getDebitTransactions = async () => {
    const options = {
      method: "GET",
      headers: new Headers({ Authorization: accessToken }),
    }
    const baseURL = process.env.REACT_APP_BASEURL
    const response = await fetch(
      `${baseURL}/api/admin/transaction/debit`,
      options
    )
    const debitTransactions = await response.json()
    setDebitTransactions(debitTransactions.data)
    setLoading(false)
    console.log(debitTransactions.data)
  }

  useEffect(() => {
    getDebitTransactions()
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Debit Transactions | Cashpally Admin</title>
        </MetaTags>
        <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
          Debit Transactions
        </h4>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row mt-3">
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Table className="table responsive table-striped table-borderless mt-2 mb-0">
                        <thead>
                          <tr style={{ color: "#cccccc" }}>
                            <th>FIRST NAME</th>
                            <th>LAST NAME</th>
                            <th>DATE INITIATED</th>
                            <th>TRANSACTION ID</th>
                            <th>PAYMENT METHOD</th>
                            <th>NARRATION</th>
                            <th>AMOUNT</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {debitTransactions.map((transaction, index) => {
                            const {
                              id,
                              amount,
                              method,
                              status,
                              narration,
                              user,
                              reference,
                              date,
                            } = transaction
                            return (
                              <tr
                                key={id}
                                onClick={() => setRedirect("/user/" + user?.id)}
                                className="table-row"
                              >
                                <td>{user?.first_name}</td>
                                <td>{user?.last_name}</td>
                                <td>
                                  {date
                                    ?.replace("T", " ")
                                    ?.replace(".000000Z", "")}
                                </td>
                                <td>{reference}</td>
                                <td>{narration ? narration : "None"}</td>
                                <td>{method}</td>
                                <CurrencyFormat
                                  value={amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                  renderText={value => <td>{value}</td>}
                                />

                                <td>{status}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DebitTransactions
