import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody } from "reactstrap"

import "../Users/datatables.scss"
import accessToken from "helpers/jwt-token-access/accessToken"
import { stubFalse } from "lodash"
import { toast, ToastContainer } from "react-toastify"

const UsersNotifications = () => {
  const [notification, setNotification] = useState({
    title: '',
    message: ''
  })
  const [loading, setLoading] = useState(stubFalse)
  const [disableButton, setDisableButton] = useState(false)


  // Send Push Notification to all users
  const sendNotification = async (e) => {
    e.preventDefault()
    if (confirm("Are you sure you want to send notifications to all users?")) {
      setNotification({
        title: "",
        message: "",
      })
      // do stuff
      setLoading(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            message: notification.message,
            title: notification.title,
          }),
        }
        const baseURL = process.env.REACT_APP_BASEURL
        const response = await fetch(
          `${baseURL}/api/admin/user/send-notification`,
          options
        )
        const data = await response.json()
        toast(data.message)
        setbuttonLoadNotif(false)
        setDisableButton(false)
      } catch (error) {
        console.log(error, "error message")
        setDisableButton(false)
        setLoading(false)
        // do what
      }
    } else {
      return false
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Notifications | Cashpally Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="py-2 m-3" style={{ color: "#21CD9A" }}>
                    Push Notifications to all users
                  </h4>
                  <div className="container">
                    <ToastContainer />
                    <div className="row">
                      <div className="col-md-6">
                        <form onSubmit={sendNotification}>
                          <div className="my-4">
                            <input
                              type="text"
                              aria-label="title"
                              className="form-control"
                              name="title"
                              placeholder="Title"
                              value={notification.title}
                              onChange={e =>
                                setNotification({
                                  ...notification,
                                  title: e.target.value,
                                })
                              }
                              required
                            />
                          </div>
                          <div className="mb-4">
                            <textarea
                              className="form-control"
                              placeholder="Message"
                              aria-label="message"
                              name="message"
                              cols="30"
                              rows="6"
                              required
                              value={notification.message}
                              onChange={e =>
                                setNotification({
                                  ...notification,
                                  message: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <button
                              type="submit"
                              className="btn btn-success"
                              disabled={disableButton}
                            >
                              Submit
                              {loading && (
                                <div
                                  className="spinner-border text-light mx-3"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    fontSize: "10px",
                                  }}
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UsersNotifications
